import React, { Suspense } from 'react';
import { Navigate, createBrowserRouter } from 'react-router-dom';

import Dashboard from './dashboard';
import RouteLoader from '../../helpers/routeLoader';
// import ClearoutUsers from "../users_list/clearout_users";
import { UserIsNotAuthenticated } from './../../authenticators'
import * as Constants from './../../helpers/constants'
import UserlistPage from '../user_list_page';
import MiscellaneousApi from '../miscellaneous/miscellaneous_api';
import ClearoutIndividualUserInfo from '../individual_user_info/clearout_individual_user_info';
import ProductList from './../products/component/list'
import CreateProduct from './../products/component/create'
import OrganizationInfo from '../org_info/org_info';
import Coupons from '../coupons/coupons';
import OrglistPage from '../org_list_page ';
import ManageAbuse from '../abuse/manage_abuse';
import UpdateHistory from '../update_history/abuse_update_history';
import AbuseLogs from '../abuse_view_logs/abuse_view_logs';
import UserActivities from '../user_activities/user_activities';
import Lists from '../email_verifier_lists';
import MembersList from '../members_list/members_list';

const router = createBrowserRouter([
  {
    id: 'dashboard-route',
    path: 'dashboard',
    element:
      <UserIsNotAuthenticated redirectPath='/login'>
        <Suspense fallback={<RouteLoader />}>
          <Dashboard />
        </Suspense>
      </UserIsNotAuthenticated>,
    children: [
      {
        id: 'dashboard-clearout-users',
        path: 'clearout_users',
        element:
          <Suspense fallback={<RouteLoader />}>
            <UserlistPage
              client={Constants.CLIENTS.CLEAROUT.NAME}
              key='clearout_users'
            />
          </Suspense>
      },
      {
        id: 'dashboard-clearout-phone-users',
        path: 'clearoutphone_users',
        element:
          <Suspense fallback={<RouteLoader />}>
            <UserlistPage
              client={Constants.CLIENTS.CLEAROUTPHONE.NAME}
              key='clearoutphone_users'
            />
          </Suspense>
      },
      {
        id: 'dashboard-clearout-miscellaneous-api',
        path: 'miscellaneous_api',
        element:
          <Suspense fallback={<RouteLoader />}>
            <MiscellaneousApi />
          </Suspense>
      },
      {
        id: 'clearout-individual-user-info',
        path: 'clearout_individual_user_info',
        element:
          <Suspense fallback={<RouteLoader />}>
            <ClearoutIndividualUserInfo
              client={Constants.CLIENTS.CLEAROUT.NAME}
              key='clearout_individual_user_info'
            />
          </Suspense>
      },
      {
        id: 'clearoutphone-individual-user-info',
        path: 'clearoutphone_individual_user_info',
        element:
          <Suspense fallback={<RouteLoader />}>
            <ClearoutIndividualUserInfo
              client={Constants.CLIENTS.CLEAROUTPHONE.NAME}
              key='clearoutphone_individual_user_info'
            />
          </Suspense>
      },
      {
        id: 'clearout-plan-list',
        path: 'clearout_plan_list',
        element:
          <Suspense fallback={<RouteLoader />}>
            <ProductList client={Constants.CLIENTS.CLEAROUT.NAME} />
          </Suspense>
      },
      {
        id: 'clearout-create-plan',
        path: 'clearout_create_plan',
        element:
          <Suspense fallback={<RouteLoader />}>
            <CreateProduct client={Constants.CLIENTS.CLEAROUT.NAME} />
          </Suspense>
      },
      {
        id: 'clearout-organization-info',
        path: 'clearout_organization_info',
        element:
          <Suspense fallback={<RouteLoader />}>
            <OrganizationInfo client={Constants.CLIENTS.CLEAROUT.NAME} />
          </Suspense>
      },
      {
        id: 'coupons',
        path: 'clearout/coupons',
        element:
          <Suspense fallback={<RouteLoader />}>
            <Coupons
              client={Constants.CLIENTS.CLEAROUT.NAME}
              key='clearout_coupons'
            />
          </Suspense>
      },
      {
        id: 'coupon',
        path: 'clearout/coupons/:couponId',
        element:
          <Suspense fallback={<RouteLoader />}>
            <Coupons
              client={Constants.CLIENTS.CLEAROUT.NAME}
              key='clearout_coupon'
              coupon='coupon_search'
            />
          </Suspense>
      },
      {
        id: 'copcoupons',
        path: 'clearoutphone/coupons',
        element:
          <Suspense fallback={<RouteLoader />}>
            <Coupons
              client={Constants.CLIENTS.CLEAROUTPHONE.NAME}
              key='clearoutphone_coupons'
            />
          </Suspense >
      },
      {
        id: 'copcoupon',
        path: 'clearoutphone/coupons/:couponId',
        element:
          <Suspense fallback={<RouteLoader />}>
            <Coupons
              client={Constants.CLIENTS.CLEAROUTPHONE.NAME}
              key='clearoutphone_coupon'
            />
          </Suspense >
      },
      {
        id: 'dashboard-clearout-manage-abuse',
        path: 'clearout/abuse_list',
        element:
          <Suspense fallback={<RouteLoader />}>
            <ManageAbuse
              key='clearout_abuse_list'
              client={Constants.CLIENTS.CLEAROUT.NAME}
            />
          </Suspense>
      },
      {
        id: 'dashboard-clearoutphone-manage-abuse',
        path: 'clearoutphone/abuse_list',
        element:
          <Suspense fallback={<RouteLoader />}>
            <ManageAbuse
              key='clearoutphone_abuse_list'
              client={Constants.CLIENTS.CLEAROUTPHONE.NAME}
            />
          </Suspense>
      },
      {
        id: 'dashboard-clearout-view-logs',
        path: 'clearout/abuse_logs',
        element:
          <Suspense fallback={<RouteLoader />}>
            <AbuseLogs
              client={Constants.CLIENTS.CLEAROUT.NAME}
              key='clearout_abuse_logs'
            />
          </Suspense>
      },
      {
        id: 'dashboard-clearoutphone-view-logs',
        path: 'clearoutphone/abuse_logs',
        element:
          <Suspense fallback={<RouteLoader />}>
            <AbuseLogs
              client={Constants.CLIENTS.CLEAROUTPHONE.NAME}
              key='clearoutphone_abuse_logs'
            />
          </Suspense>
      },
      {
        id: 'dashboard-clearout-update-logs',
        path: 'clearout/abuse_update_logs',
        element:
          <Suspense fallback={<RouteLoader />}>
            <UpdateHistory
              client={Constants.CLIENTS.CLEAROUT.NAME}
              key='clearout_abuse_update_logs'
            />
          </Suspense>
      },
      {
        id: 'dashboard-clearoutphone-update-logs',
        path: 'clearoutphone/abuse_update_logs',
        element:
          <Suspense fallback={<RouteLoader />}>
            <UpdateHistory
              key='clearoutphone_abuse_update_logs'
              client={Constants.CLIENTS.CLEAROUTPHONE.NAME}
            />
          </Suspense>
      },
      {
        id: 'dashboard-clearout-org-users',
        path: 'clearout_org_list',
        element:
          <Suspense fallback={<RouteLoader />}>
            <OrglistPage client={Constants.CLIENTS.CLEAROUTS.NAME} />
          </Suspense>
      },
      {
        id: 'dashboard-user-activities',
        path: 'clearout_individual_user_activity',
        element:
          <Suspense fallback={<RouteLoader />}>
            <UserActivities
              client={Constants.CLIENTS.CLEAROUT.NAME}
              key='clearout_user_activities'
            />
          </Suspense>
      },
      {
        id: 'dashboard-cop-user-activities',
        path: 'cop_individual_user_activity',
        element:
          <Suspense fallback={<RouteLoader />}>
            <UserActivities
              client={Constants.CLIENTS.CLEAROUTPHONE.NAME}
              key='clearoutphone_user_activities'
            />
          </Suspense>
      },
      {
        id: 'clearout_organization_user',
        path: 'clearout_organization_users',
        element:
          <Suspense fallback={<RouteLoader />}>
            <MembersList
              client={Constants.CLIENTS.CLEAROUT.NAME}
              key='members_list'
            />
          </Suspense>
      },
      {
        id: 'dashboard-redirect',
        path: '*',
        element:
          <Navigate
            to={'/dashboard/clearout_users'}
            replace={true}
          />
      },
      {
        id: 'dashboard-clearout-email-verifier-list',
        path: 'clearout-email-verifier-lists',
        element:
          <Suspense fallback={<RouteLoader />}>
            <Lists client={Constants.CLIENTS.CLEAROUT.NAME}/>
          </Suspense>
      },
    ]
  }
])

export default router
