import React, { Component } from 'react';
import { Modal, Button, Row, Col } from 'react-bootstrap';
import classnames from 'classnames';

import ApiService from '../../helpers/apiservice';
import * as Constants from '../../helpers/constants';
import * as Helper from '../../helpers/helper';
import showToastNotification from '../widgets/toastify'

class DownloadModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: props.showDownloadModal,
      listDetails: props.listDetails,
      errors: {},
      validationFilter: {
        valid: true,
        invalid: true,
        catch_all: true,
        unknown: true
      },
      selectedDownloadOption: 'guaranteed_deliverable',
      showImmDownload: 'email_with_std_co_col',
      downloadDisabled: false,
      errorMessageIfNoValueFound: '',
      labelDescription: Constants.DELIVERABLES_GUARANTEED,
      deliverablesLabel: 'Guaranteed Deliverables',
      deliverable_duplicates: true,
      non_deliverable_duplicates: true,
      deliverable_with_risk_duplicates: true,
      custom_duplicates: true,
      all_result_duplicates: true,
    };
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(prevProps) !== JSON.stringify(this.props)) {
      let selectedOption = 'guaranteed_deliverable',
        showImmDownload = 'email_with_std_co_col';
      if (
        this.props.listDetails &&
        this.props.listDetails.details &&
        this.props.listDetails.details.default_generated_file
      ) {
        selectedOption = this.props.listDetails.details.default_generated_file;
        showImmDownload = this.props.listDetails.details.default_generated_file;
      }
      this.setState({
        showModal: this.props.showDownloadModal,
        listDetails: this.props.listDetails,
        selectedDownloadOption: selectedOption,
        showImmDownload,
      }, () => {
        this.getDownloadOptions(this.state.selectedDownloadOption);
      });
    }
  }

  toggleModal = () => {
    let validationFilter = {
      valid: true,
      invalid: true,
      catch_all: true,
      unknown: true
    };
    this.setState(
      {
        showModal: !this.state.showModal,
        validationFilter,
        errors: {},
        selectedDownloadOption: 'guaranteed_deliverable',
        downloadDisabled: false,
        errorMessageIfNoValueFound: '',
        deliverable_duplicates: true,
        non_deliverable_duplicates: true,
        deliverable_with_risk_duplicates: true,
        custom_duplicates: true,
        all_result_duplicates: true,
      },
      () => {
        this.props.closeDownloadModal();
      }
    );
  }

  onDownloadOptionSelect = val => {
    this.setState(
      {
        selectedDownloadOption: val
      },
      () => {
        this.getDownloadOptions(this.state.selectedDownloadOption);
      }
    );
  }

  onValidationFilterOptsChange = val => {
    let validationFilter = this.state.validationFilter;
    validationFilter[val] = !this.state.validationFilter[val];
    this.setState({
      validationFilter
    });
  }

  validateDownloadSubmit = () => {
    let valid = true;
    let errors = {};
    let validationFilter = Constants.DOWNLOAD_VALIDATION_FILTER.filter((element) => {
      return this.state.validationFilter[element] && this.state.listDetails.verification_result[element].value > 0;
    });

    if (this.state.selectedDownloadOption === 'custom' && validationFilter.length < 1) {
      valid = false;
      errors['validation_filter_opts'] = 'Please select atleast one status filter option';
    }
    if (!this.state.selectedDownloadOption) {
      valid = false;
      errors['download_selected_option'] = 'Please select atleast one option to download file';
    }
    this.setState({ errors });
    return valid;
  }

  downloadResultWithPolling = body => {
    var downloadWin;
    ApiService.downloadresultForClearoutList(body)
      .then(res => {
        if (res.status === Constants.SF_API.Success) {
          if (res.data.download_queue_id) {
            downloadWin = window.open(
              '/download_result/' + Math.floor(Math.random() * 90000) + 10000
            );
            downloadWin.location = '/download_result/' + res.data.download_queue_id;
            downloadWin.focus();
            this.toggleModal();
          } else if (res.data && res.data.url) {
            downloadWin = window.open('/download/result');
            this.toggleModal();
            setTimeout(() => {
              downloadWin.location = res.data.url;
              downloadWin.focus();
            }, 1000);
            setTimeout(() => {
              downloadWin.close();
            }, 30000);
          }
        } else {
          this.showDownloadFailedErrMessage(res);
        }
      })
      .catch(err => {
        this.showDownloadFailedErrMessage(err);
        console.log('list info api erred', err);
      });
  };

  downloadResultWithoutPolling = body => {
    var downloadWindow;
    ApiService.downloadresultForClearoutList(body)
      .then(res => {
        if (res.status === Constants.SF_API.Success) {
          if (res.data && res.data.message) {
            downloadWindow = window.open('/download/result?q=true&n=' + Helper.truncateMiddle(this.state.listDetails.details.name, 14));
            this.toggleModal();
            setTimeout(() => {
              downloadWindow.focus();
            }, 1000);
            setTimeout(() => {
              downloadWindow.close();
            }, 30000);
          } else if (res.data && res.data.url) {
            downloadWindow = window.open('/download/result');
            this.toggleModal();
            setTimeout(() => {
              downloadWindow.location = res.data.url;
              downloadWindow.focus();
            }, 1000);
            setTimeout(() => {
              downloadWindow.close();
            }, 30000);
          }
        } else {
          this.showDownloadFailedErrMessage(res);
        }
      })
      .catch(err => {
        this.showDownloadFailedErrMessage(err);
        this.toggleModal();
        console.log('list info api erred', err);
      });
  }
    
  getDownloadOptions = (option) => {
    let safeToSendExists = false, 
      validationFilter = [], 
      downloadDisabled = false, 
      errorMessageIfNoValueFound = '',
      email_with_risk = 0,
      labelDescription = '',
      deliverablesLabel = 'Guaranteed Deliverables'
    if( this.state.listDetails.verification_result && this.state.listDetails.verification_result.safe_to_send){
      safeToSendExists = true
    }
    let body = {
      list_id: this.state.listDetails.list_id,
      co_user_id: this.state.listDetails.user_details.user_id,
      user_id: this.props.user.id,
    }

    switch (option) {
      case 'guaranteed_deliverable':
        if (safeToSendExists) {
          body.options = {
            'filters': {
              'safe_to_send': 'deliverables'
            },
            name: option,
            is_immediate_download: this.state.listDetails.details && this.state.listDetails.details.is_immediate_download,
            append_result: true,
            duplicates: false
          }
          if (this.state.listDetails.verification_result && this.state.listDetails.verification_result.safe_to_send['yes'].value < 1) {
            downloadDisabled = true
            errorMessageIfNoValueFound = 'Please select other option, as there are no deliverables email address in the list'
          }
          labelDescription = Constants.DELIVERABLES_GUARANTEED
        } else {
          body.options = {
            'filters': {
              'status': ['valid']
            },
            is_immediate_download: this.state.listDetails.details && this.state.listDetails.details.is_immediate_download,
            name: option,
            append_result: true,
            duplicates: false,
          }
          if (this.state.listDetails.verification_result && this.state.listDetails.verification_result['valid'].value < 1) {
            downloadDisabled = true
            errorMessageIfNoValueFound = 'Please select other option, as there are no valid email addresses in this list'
          }
          labelDescription = Constants.DELIVERABLES
          deliverablesLabel = 'Deliverables'
        }
        break

      case 'non_deliverable':
        if (safeToSendExists) {
          body.options = {
            'filters': {
              'safe_to_send': 'non_deliverables'
            },
            is_immediate_download: this.state.listDetails.details && this.state.listDetails.details.is_immediate_download,
            name: option,
            append_result: true,
            duplicates: false,
          }
          if (this.state.listDetails.verification_result && this.state.listDetails.verification_result.safe_to_send['no'].value < 1) {
            downloadDisabled = true
            errorMessageIfNoValueFound = 'Please select other option, as there are no non-deliverables email address in the list'
          }
          labelDescription = Constants.NON_DELIVERABLES_SAFE_TO_SEND
        } else {
          body.options = {
            'filters': {
              'status': ['invalid']
            },
            is_immediate_download: this.state.listDetails.details && this.state.listDetails.details.is_immediate_download,
            name: option,
            append_result: true,
            duplicates: false
          }
          if (this.state.listDetails.verification_result && this.state.listDetails.verification_result['invalid'].value < 1) {
            downloadDisabled = true
            errorMessageIfNoValueFound = 'Please select other option, as there are no invalid email addresses in this list'
          }
          labelDescription = Constants.NON_DELIVERABLES
        }
        break

      case 'guaranteed_deliverables_with_risk':
        body.options = {
          filters: {
            safe_to_send: 'deliverables_with_risk',
          },
          is_immediate_download: this.state.listDetails.details && this.state.listDetails.details.is_immediate_download,
          name: option,
          append_result: true,
          duplicates: false,
        }
        email_with_risk =
      this.state.listDetails.verification_result.safe_to_send['yes'].value +
      this.state.listDetails.verification_result.safe_to_send['risky'].value
        if (this.state.listDetails.verification_result && email_with_risk < 1) {
          downloadDisabled = true
          errorMessageIfNoValueFound = 'Please select other option, as there are no deliverables with risk email addresses in this list'
        }
        labelDescription = (
          <div>
            <p className='mb-0'>
          The result will include Guaranteed Deliverables (mentioned above) and the email addresses that are
          determined risky. The risk factor depends upon multiple reasons like
            </p>
            <ul style={{ paddingLeft: '15px', marginBottom: '0' }}>
              <li>Low deliverability score</li>
              <li>High volume of role-based email addresses</li>
              <li>Any temporary mail account issue</li>
              <li>Mail server configured to accept all email messages </li>
            </ul>
            <p className='mb-0'>
          The email addresses of this result file are safe to use when the bounce rate is strictly below 5% or using
          own email sending platform without dependent on external Email Service Provider (ESP).
            </p>
          </div>
        )
        break

      case 'custom':
        validationFilter = Constants.DOWNLOAD_VALIDATION_FILTER.filter((element) => {
          return this.state.validationFilter[element] && this.state.listDetails.verification_result[element].value > 0
        })
        body.options = {
          filters: {
            status: validationFilter,
          },
          is_immediate_download: this.state.listDetails.details && this.state.listDetails.details.is_immediate_download,
          name: option,
          append_result: true,
          duplicates: !this.state.custom_duplicates,
        }
        break

      case 'email_with_std_co_col':
        body.options = {
          is_immediate_download: this.state.listDetails.details && this.state.listDetails.details.is_immediate_download,
          name: option,
          append_result: false,
        }
        break

      default:
        body.options = {
          is_immediate_download: this.state.listDetails.details && this.state.listDetails.details.is_immediate_download,
          name: option,
          append_result: false
        }
        break
    }
      
    this.setState({
      downloadDisabled,
      errorMessageIfNoValueFound,
      labelDescription,
      deliverablesLabel
    })
    return body
  }

  onDownloadSubmit = () => {
    if (this.validateDownloadSubmit()) {
      let body = this.getDownloadOptions(this.state.selectedDownloadOption)

      if (this.state.listDetails.details.is_immediate_download) {
        this.downloadResultWithPolling(body)
      } else {
        this.downloadResultWithoutPolling(body)
      }
    }
  }

  showDownloadFailedErrMessage = (err) => {
    let notificationDetails = {
      message: err.error ? err.error.message : err.message,
      isSuccess: false,
    }
    showToastNotification(notificationDetails)
    this.toggleModal()
  }

  showDownloadQueudMessage = (res) => {
    let message  = <span>Your request to download list <b title={this.state.listDetails.details.name}>{Helper.truncateMiddle(this.state.listDetails.details.name, 14)}</b> result file has been accepted. You will be notified through email once the result file is available for download.</span>
    let notificationDetails = {
      message: res.data ? message : res.data,
      isSuccess: true,
    }
    showToastNotification(notificationDetails)
    this.toggleModal()
  }

  onDuplicateSelect = (type) => {
    switch (type) {
      case 'deliverable_duplicates':
        this.setState({
          deliverable_duplicates: !this.state.deliverable_duplicates,
        })
        break
      case 'deliverable_with_risk_duplicates':
        this.setState({
          deliverable_with_risk_duplicates: !this.state.deliverable_with_risk_duplicates,
        })
        break
      case 'non_deliverable_duplicates':
        this.setState({
          non_deliverable_duplicates: !this.state.non_deliverable_duplicates,
        })
        break
      case 'all_result_duplicates':
        this.setState({
          all_result_duplicates: !this.state.all_result_duplicates,
        })
        break
      default:
        this.setState({
          custom_duplicates: !this.state.custom_duplicates,
        })
    }
  }

  isImmediateDownload = () => {
    if(this.state.listDetails && 
      this.state.listDetails.file_download &&
      this.state.listDetails.file_download.allow && 
              this.state.listDetails.file_download.options.allow_immediate_download
    )
    {
      return true
    }
    else return false
  }

  isCustomDownload = () => {
    
    if(this.state.listDetails && 
      this.state.listDetails.file_download &&
      this.state.listDetails.file_download.allow && 
              this.state.listDetails.file_download.options.allow_custom_download
    ){
      return true
    }
    else return false
  }

  render() {
    let duplicateDisabled = (this.state.listDetails.verification_result && this.state.listDetails.verification_result.duplicate && this.state.listDetails.verification_result.duplicate.value === 0) ? true : false
    return (
      <Modal
        show={this.state.showModal}
        onHide={this.toggleModal}
        className="download-result-modal"
        size='lg'
      >
        <Modal.Header closeButton>
          <Modal.Title>Download Verified Result</Modal.Title>
        </Modal.Header>{' '}
        <Modal.Body>
          <Row style={{ borderBottom: '1px solid #f4f5f7' }}>
            <Col sm={12}>
              <p className="mb-3">
                Please choose how you wish to download the result file for list:{' '}
                <span
                  className="file-name"
                  title={this.state.listDetails.details.name}
                >
                  <b>{Helper.truncateMiddle(this.state.listDetails.details.name, 14)}</b>
                </span>
              </p>
            </Col>
          </Row>
          <Row className="download-container">
            <Col
              sm={6}
              className="append-checkbox-div"
            >
              <div className="radio">
                <label>
                  <input
                    type="radio"
                    checked={
                      this.state.selectedDownloadOption === 'guaranteed_deliverable'
                        ? true
                        : false
                    }
                    name="append"
                    className="append-checkbox"
                    value="guaranteed_deliverable"
                    id="Deliverables"
                    onChange={() => this.onDownloadOptionSelect('guaranteed_deliverable')}
                  />
                  {this.state.deliverablesLabel}
                  { this.state.showImmDownload === 'guaranteed_deliverable' ? <b className="all-result-text">(Immediate download)</b> : null }
                </label>
              </div>
              {this.state.listDetails.verification_result &&
              this.state.listDetails.verification_result.safe_to_send ? (
                  <div className="radio">
                    <label>
                      <input
                        type="radio"
                        checked={
                          this.state.selectedDownloadOption ===
                        'guaranteed_deliverables_with_risk'
                            ? true
                            : false
                        }
                        name="append"
                        className="append-checkbox"
                        value="guaranteed_deliverables_with_risk"
                        id="non-deliverables"
                        onChange={() =>
                          this.onDownloadOptionSelect('guaranteed_deliverables_with_risk')
                        }
                      />
                    Deliverables With Risk
                      { this.state.showImmDownload === 'guaranteed_deliverables_with_risk' ? <b className="all-result-text">(Immediate download)</b> : null }
                    </label>
                  </div>
                ) : null}
              <div className="radio">
                <label>
                  <input
                    type="radio"
                    checked={
                      this.state.selectedDownloadOption === 'non_deliverable'
                        ? true
                        : false
                    }
                    name="append"
                    className="append-checkbox"
                    value="non_deliverable"
                    id="non-deliverables"
                    onChange={() =>
                      this.onDownloadOptionSelect('non_deliverable')
                    }
                  />
                  Non - Deliverables
                  { this.state.showImmDownload === 'non_deliverable' ? <b className="all-result-text">(Immediate download)</b> : null }
                </label>
              </div>
              <div className="radio">
                <label>
                  <input
                    type="radio"
                    checked={
                      this.state.selectedDownloadOption === 'email_with_std_co_col'
                        ? true
                        : false
                    }
                    name="append"
                    className="append-checkbox"
                    value="email_with_std_co_col"
                    id="all-result"
                    onChange={() => this.onDownloadOptionSelect('email_with_std_co_col')}
                  />
                  Email addresses with Clearout standard columns
                  { this.state.showImmDownload === 'email_with_std_co_col' ? <b className="all-result-text">(Immediate download)</b> : null }
                </label>
              </div>
              <div className="radio">
                <label>
                  <input
                    type="radio"
                    checked={
                      this.state.selectedDownloadOption === 'custom'
                        ? true
                        : false
                    }
                    name="append"
                    className="append-checkbox"
                    value="custom"
                    id="all-result"
                    onChange={() => this.onDownloadOptionSelect('custom')}
                  />
                  Custom
                  { this.state.showImmDownload === 'custom' ? <b className="all-result-text">(Immediate download)</b> : null }
                </label>
              </div>
            </Col>
            <Col
              sm={6}
              className="download-option-div"
            >
              <div
                className={classnames(
                  'download-option-desc',
                  this.state.selectedDownloadOption === 'guaranteed_deliverable' ? 'selected-option' : ''
                )}
              >
                <h3 className="option-heading">{this.state.deliverablesLabel}</h3>
                <div className="option-desc pb-1">{this.state.labelDescription}</div>
                <p className={classnames(this.state.errorMessageIfNoValueFound ? 'option-desc mt-2 mb-2' : 'd-none')}>
                  <b>{this.state.errorMessageIfNoValueFound}</b>
                </p>
              </div>
              <div
                className={classnames(
                  'download-option-desc',
                  this.state.selectedDownloadOption === 'guaranteed_deliverables_with_risk' ? 'selected-option' : ''
                )}
              >
                <h3 className="option-heading">
               Deliverables With Risk
                </h3>
                <div className="option-desc">{this.state.labelDescription}</div>
                <p className={classnames(this.state.errorMessageIfNoValueFound ? 'option-desc mt-2 mb-2' : 'd-none')}>
                  <b>{this.state.errorMessageIfNoValueFound}</b>
                </p>
              </div>
              <div
                className={classnames(
                  'download-option-desc',
                  this.state.selectedDownloadOption === 'non_deliverable' ? 'selected-option' : ''
                )}
              >
                <div>
                  <h3 className="option-heading">
                    Non - Deliverables

                  </h3>
                  <div className="option-desc">{this.state.labelDescription}</div>
                  <p className={classnames(this.state.errorMessageIfNoValueFound ? 'option-desc mt-2 mb-2' : 'd-none')}>
                    <b>{this.state.errorMessageIfNoValueFound}</b>
                  </p>
                </div>
              </div>
              <div
                className={classnames(
                  'download-option-desc',
                  this.state.selectedDownloadOption === 'email_with_std_co_col' ? 'selected-option' : ''
                )}
              >
                <h3 className="option-heading">
                  Email addresses with Clearout standard columns
                </h3>
                <p className="option-desc">
                  The result file will include all verified status email
                  addresses – Valid, Invalid, Catch All, Unknown appended to
                  other Clearout columns. Duplicates, if any, will not be
                  included in the result file. Your original file columns will
                  not be included in the result file.
                </p>
              </div>
              <div
                className={classnames(
                  'download-option-desc',
                  this.state.selectedDownloadOption === 'custom' ? 'selected-option' : ''
                )}
              >
                <div>
                  <h3 className="option-heading">Custom</h3>
                  <p className="option-desc ">
                  The result file will include your original list together with
                  the columns you choose further- Valid, Invalid, Catch All and
                  Unknown. Select columns you want to filter by :
                  </p>
                  <div>
                    {Constants.DOWNLOAD_VALIDATION_FILTER.map((column, index) => {
                      let val = Constants.VALIDATION_FILTERS[column].value;
                      let colValue =
                      this.state.listDetails &&
                      this.state.listDetails.verification_result &&
                      this.state.listDetails.verification_result[column].value;
                      let total =
                      this.state.listDetails &&
                      this.state.listDetails.verification_result &&
                      this.state.listDetails.verification_result['total'];
                      let isDisabled = colValue > 0 ? false : true;
                      let isChecked = !isDisabled
                        ? this.state.validationFilter[val]
                        : false;
                      return (
                        <div
                          className="checkbox"
                          key={index}
                        >
                          <label className="append-checkbox-values">
                            <input
                              className="filter-checkbox-values"
                              type="checkbox"
                              disabled={isDisabled}
                              checked={isChecked}
                              value={val}
                              onChange={() =>
                                this.onValidationFilterOptsChange(val)
                              }
                            />
                            {Constants.VALIDATION_FILTERS[column].name}
                            {total ? (
                              <span className="filter-val">
                              ({colValue} of {total})
                              </span>
                            ) : null}
                          </label>
                        </div>
                      );
                    })}
                  </div>
                  <label
                    className={classnames(
                      this.state.errors['validation_filter_opts']
                        ? 'd-block error-message'
                        : 'd-none'
                    )}
                  >
                    {this.state.errors['validation_filter_opts']}
                  </label>
                  <label className="append-checkbox-values">
                    <input
                      className="filter-checkbox-values"
                      type="checkbox"
                      checked = {this.state.custom_duplicates}
                      value={'custom_duplicates'}
                      disabled={duplicateDisabled}
                      onChange = {() => this.onDuplicateSelect('custom_duplicates')}

                    />
                          &nbsp;Remove Duplicates
                  </label>
                </div>
                <label
                  className={classnames(
                    this.state.errors['validation_filter_opts'] ? 'd-block error-message' : 'd-none'
                  )}
                >
                  {this.state.errors['validation_filter_opts']}
                </label>

              </div>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <div>
            <label
              className={classnames(this.state.errors['download_selected_option'] ? 'd-block error-message' : 'd-none')}
            >
              {this.state.errors['download_selected_option']}
            </label>
          </div>
          <Button
            className="btn-clearout m-1"
            type="submit"
            onClick={this.onDownloadSubmit}
            disabled={this.state.downloadDisabled}
          >
            <i className={'fa fa-download px-2 '}></i>
            Download Result
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default DownloadModal;
