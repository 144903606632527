import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { CompactTable } from '@table-library/react-table-library/compact';
import { useTheme } from '@table-library/react-table-library/theme';
import { getTheme } from '@table-library/react-table-library/baseline';
import { usePagination } from '@table-library/react-table-library/pagination';
import { isMobile } from 'react-device-detect'
import { Link } from 'react-router-dom';
import ClipboardCopy from '../widgets/clipboard_copy';
import * as Helpers from '../../helpers/helper';
import { Badge } from 'react-bootstrap'
import CustomOverlayTrigger from '../widgets/overlay_trigger'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import * as Constants from '../../helpers/constants';

import 'react-toggle/style.css';
import '../../static/css/abuse.css';

const initialState = {
  pageSize: 50,
  currentValue: {},
  currentPageIndex: 0,
};

const MembersListTable = ({ getAbuseList, data }) => {
  const [state, setState] = useState(initialState);
  // const theme = useTheme(getTheme());
  // let maxRows = Math.min(data.nodes.length, state.pageSize) + 1 //header plus rows
  const tableTheme = useTheme(
    [
      getTheme(),
      {
        Table: `
        --data-table-library_grid-template-columns: 
          minmax(20rem, 1fr) // user
          minmax(8rem, 1fr) // status
          minmax(18rem, 1fr) // credits
          minmax(10rem, 1fr) // Limits
          minmax(12rem, 1fr) // Teams
          minmax(12rem, 1fr); // stats
          margin: 0px;
          grid-auto-rows: minmax(max-content, max-content);
        `,
        HeaderCell: `
        height: fit-content;
      `,
        Row: `
          font-size:14px;
          height: max-content;
        `
      }
    ]
  )

  useEffect(() => {
    getAbuseList(state.currentPageIndex * state.pageSize, state.pageSize, null, { created_on: 'desc' });
  }, [getAbuseList, state.pageSize, state.currentPageIndex]);


  const COLUMNS = [
    { label: 'User', renderCell: (item) => titleFormat(item) },
    { label: 'Status', renderCell: (item) => statusFormatter(item) },
    { label: 'Credits', renderCell: (item) => creditsFormat(item) },
    { label: 'Limits', renderCell: (item) => limitsFormatter(item) },
    { label: 'Teams', renderCell: (item) => teamFormatter(item) },
    { label: 'Stats', renderCell: (item) => statsFormatter(item) },
  ];

  const handlePageSizeChange = (event) => {
    const newSize = parseInt(event.target.value, 10);
    setState((prevState) => ({ ...prevState, pageSize: newSize, currentPageIndex: 0 }));
    getAbuseList(0, newSize, null, { created_on: 'desc' });
  };

  const onPaginationChange = (action, newState) => {
    getAbuseList(newState.page * LIMIT, LIMIT, null, { created_on: 'desc' });
    setState((prevState) => ({
      ...prevState,
      currentPageIndex: newState.page,
    }));
  };

  const LIMIT = state.pageSize;
  const pagination = usePagination(
    data,
    {
      state: {
        page: state.currentPageIndex,
        size: LIMIT,
      },
      onChange: onPaginationChange,
    }
  );

  // formatters

  const titleFormat = (item) => {
    let data = item.activated_on ?
      item.account_status !== 'removed' ?
        <div>
          <Link
            to={'/dashboard/clearout_individual_user_info?user_id=' + item._id + '&user_type=' + (item.user_type ? item.user_type : 'individual')}
            title={item.email}
            target={'_blank'}
            className='text-decoration-none'
          >
            {item.name}
          </Link> &nbsp;&nbsp;
          <small>({Helpers.capitalizeFirstLetter(
            item.user_role === 'analyst'
              ? 'Executive'
              : item.user_role === 'admin'
                ? 'Manager'
                : item.user_role
          )})
          </small>
          <br />
          <small>
            <div className='d-flex'>
              <ClipboardCopy textToCopy={item.email} />
              &nbsp;&nbsp;{item.email}
            </div>
          </small>
        </div> :
        <div>
          <Link
            to={'/dashboard/clearout_individual_user_info?user_id=' + item._id + '&user_type=' + (item.user_type ? item.user_type : 'individual')}
            title={item.email}
            target={'_blank'}
            className='text-decoration-none'
          >
            {item.name}
          </Link> &nbsp;&nbsp;
          <small>({Helpers.capitalizeFirstLetter(
            item.user_role === 'analyst'
              ? 'Executive'
              : item.user_role === 'admin'
                ? 'Manager'
                : item.user_role
          )})
          </small>
          <br />
          <small>
            <div className='d-flex'>
              <ClipboardCopy textToCopy={item.email} />
              <p className='mb-0 ml-2'>
                {item.email}
              </p>
            </div>
          </small>
        </div>
      :
      <div>
        <Link
          to={'/dashboard/clearout_individual_user_info?user_id=' + item._id + '&user_type=' + (item.user_type ? item.user_type : 'individual')}
          title={item.email}
          target={'_blank'}
        >
          -
        </Link>&nbsp;&nbsp;
        <small>({Helpers.capitalizeFirstLetter(
          item.user_role === 'analyst'
            ? 'Executive'
            : item.user_role === 'admin'
              ? 'Manager'
              : item.user_role
        )})
        </small>
        <br />
        <small>
          <div className='d-flex'>
            <ClipboardCopy textToCopy={item.email} />
            &nbsp;&nbsp;{item.email}
          </div>
        </small>
      </div>;

    return data;
  };

  const statusFormatter = (item) => {
    let displayStatus = item.account_status == 'removed' ?
      <p className='mb-0'>{'Removed'}</p> :
      item.email_verified ? Helpers.capitalizeFirstLetter(item.status)
        ? Helpers.capitalizeFirstLetter(item.status) : '-'
        : 'Yet to Activate'
    return displayStatus
  }

  const teamFormatter = (item) => {
    if (item.limits && item.limits.teams && item.limits.teams.length > 0) {
      let displayBatch = item.limits.teams.map((team, index) => (
        <Badge key={index}>
          <span>{team.name}</span>
        </Badge>
      ));

      return (<div>{displayBatch}</div>);
    } else {
      return '-'
    }
  };
  const creditsFormat = (item) => {
    const availableCredits = item.credits?.available;

    if (availableCredits === null) {
      return <i className='mb-0'>{'Unlimited'}</i>;
    }

    const title = availableCredits > 999 ? Number(availableCredits).toLocaleString() : availableCredits;

    return (
      <div>
        <CustomOverlayTrigger
          content={title}
          placement='bottom'
        >
          <i className='mb-0'>{availableCredits}</i>
        </CustomOverlayTrigger>
      </div>
    );
  };


  const limitsFormatter = (item) => {
    return (
      item.credits.available_daily_limit !== null || item.limits.allowed_daily_limit !== null ? (
        <div>
          {item.credits.available_daily_limit !== null ? (
            <p
              className='mb-0'
              title="Remaining Daily Limit"
            >
              RDL :&nbsp;&nbsp;{Number(item.credits.available_daily_limit).toLocaleString()}
            </p>
          ) : null}
          {item.limits.allowed_daily_limit !== null ? (
            <p
              className='mb-0'
              title="Allowed Daily Limit"
            >
              ADL :&nbsp;&nbsp;{Number(item.limits.allowed_daily_limit).toLocaleString()}
            </p>
          ) : null}
        </div>
      ) : 'No Daily Limit'
    );
  };

  const statsFormatter = (item) => (
    <div className='d-flex'>
      {/* <div className='px-2'>
        <UserModal
          cell={item}
          selectedUser={item}
        />
      </div> */}
      <div className='px-2'>
        <CustomOverlayTrigger
          content={`Click here to get the activities of ${item.name}`}
          placement='bottom'
        >
          <Link
            to={`/dashboard/clearout_individual_user_activity?user_id=${item._id}`}
            target='blank'
          >
            <FontAwesomeIcon
              icon='fa-brands fa-wpexplorer'
            />
          </Link>
        </CustomOverlayTrigger>
      </div>
      <div className='px-2'>
        <CustomOverlayTrigger
          content={`Click here to get the email verifier lists of ${item.name}`}
          placement='bottom'
        >
          <Link
            to={`/dashboard/clearout-email-verifier-lists?user_email=${item.email}`}
            target='_blank'
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon
              icon='fa-solid fa-sheet-plastic'
            />
          </Link>
        </CustomOverlayTrigger>
      </div>
      <div className='px-2'>
        <CustomOverlayTrigger
          content={`Click here to get the email finder lists of ${item.name}`}
          placement='bottom'
        >
          <Link
            to={`${OLD_CRM_URL}dashboard/clearout_emailfinder_lists?user_email=${item.email}`}
            target='blank'
          >
            <FontAwesomeIcon
              icon='fa-solid fa-list-ul'
            />
          </Link>
        </CustomOverlayTrigger>
      </div>
    </div>
  );

  return (
    <>
      <div className='table-container'>
        {data.nodes.length === 0 ? (
          <table className='table'>
            <thead className='fs-6'>
              <tr>
                {COLUMNS.map((column, index) => (
                  <th
                    className='fw-bold'
                    key={index}
                  >
                    {column.label}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td
                  colSpan={COLUMNS.length}
                  className='text-center pt-4'
                >
                  No data found
                </td>
              </tr>
            </tbody>
          </table>
        ) : (
          <CompactTable
            columns={COLUMNS}
            data={data}
            theme={tableTheme}
            layout={{ fixedHeader: true, horizontalScroll: !isMobile, custom: true }}
          />
        )}
      </div>
      <div className='d-flex justify-content-between pt-3'>
        <Form.Select
          value={state.pageSize}
          onChange={handlePageSizeChange}
          className='vertical-pagination'
        >
          {Constants.PAGE_SIZE.values.map((size) => (
            <option
              key={size}
              value={size}
            >
              {size}
            </option>
          ))}
        </Form.Select>
        <div>
          <button
            type='button'
            disabled={pagination.state.page === 0}
            onClick={() => pagination.fns.onSetPage(0)}
          >
            {'|<'}
          </button>
          <button
            type='button'
            disabled={pagination.state.page === 0}
            onClick={() => pagination.fns.onSetPage(pagination.state.page - 1)}
          >
            {'<'}
          </button>
          <button
            type='button'
            onClick={() => pagination.fns.onSetPage(pagination.state.page + 1)}
          >
            {'>'}
          </button>
        </div>
      </div>
    </>
  );
};

export default MembersListTable;
