import React, { useState, useEffect } from 'react';
import { Pie } from 'react-chartjs-2';
import * as Constants from '../../helpers/constants';

const PieChart = (props) => {
  const [graphData, setGraphData] = useState({
    labels: Constants.PIECHART_RESULT_ORDER,
    datasets: [{
      data: [1, 1, 1, 1],
      backgroundColor: Constants.GRAPH_COLORS,
      hoverBackgroundColor: Constants.GRAPH_COLORS
    }]
  });

  const options = {
    animation: false,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: 'bottom',
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            const label = tooltipItem.label || '';
            return label;
          },
          afterLabel: function (tooltipItem) {
            const total = props.graphData && props.graphData.total ? props.graphData.total : 1;
            const value = tooltipItem.raw;
            const percent = Math.round(((value / total) * 100));
            return value + ' (' + percent + '%)';
          }
        }
      }
    }
  };

  const setGraphDataFromProps = (data) => {
    let chartData = {};
    chartData.labels = Constants.PIECHART_RESULT_ORDER.map(result => {
      return Constants.RESULTS[result].display_name;
    });
    chartData.data = Constants.PIECHART_RESULT_ORDER.map(result => {
      return data.graphData[result].value;
    });
    return chartData;
  };

  useEffect(() => {
    if (props.graphData && !props.isGraphDataLoading) {
      const data = setGraphDataFromProps(props);
      setGraphData({
        labels: data.labels,
        datasets: [{
          data: data.data,
          backgroundColor: Constants.GRAPH_COLORS,
          hoverBackgroundColor: Constants.GRAPH_COLORS
        }]
      });
    }
  }, [props]);

  return (
    <Pie
      data={graphData}
      height={210}
      width={210}
      options={options}
    />
  );
};

export default PieChart;
