import React, { useEffect, useState } from 'react';
import { Accordion, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const SideBar = ({ isSideBarOpen }) => {

  const [isMenusOpen, setIsMenuOpen] = useState(isSideBarOpen)

  useEffect(() => {
    setIsMenuOpen(isSideBarOpen)
  }, [isSideBarOpen])

  /* 
      isSideBarOpen   isMenuOpen    Behaviour
        true            true          true
        false           false         false
        false           true          true  
  */

  const sidebarMouseEnter = () => {
    if (!isSideBarOpen)
      setIsMenuOpen(true)
  }

  const sidebarMouseLeave = () => {
    if (!isSideBarOpen)
      setIsMenuOpen(false)
  }


  return (
    <aside
      className={'sidebar' + (isSideBarOpen || isMenusOpen ? '' : ' close')}
      id='sidebar'
    >
      <Accordion
        className={isSideBarOpen || isMenusOpen ? '' : 'close'}
        defaultActiveKey={'clearout'}
        flush
        onMouseEnter={sidebarMouseEnter}
        onMouseLeave={sidebarMouseLeave}
        alwaysOpen={true}
      >
        {/* <Accordion.Item eventKey='labs'>
          <Accordion.Header>
            <img
              src={require('../../static/img/Kintegra_Favicon_32X32 .png')}
              alt='Labs'
            />
            <span>Labs</span>
          </Accordion.Header>
          <Accordion.Body>
            <Nav className='flex-column'>
              <Link
                className='nav-link'
                to='#users'
              >
                <img
                  src={require('../../static/img/Kintegra_Favicon_32X32 .png')}
                  alt='labs'
                  width='16'
                  height='16'
                />
                Users Lists
              </Link>
            </Nav>
          </Accordion.Body>
        </Accordion.Item> */}
        {/* <Accordion.Item eventKey='kintegra'>
          <Accordion.Header>
            <img
              src={require('../../static/img/kintegra-favicon-without-BG.png')}
              alt='Kintegra'
            />
            <span>Kintegra</span>
          </Accordion.Header>
          <Accordion.Body>
            <Nav className='flex-column'>
              <Link
                className='nav-link'
                to='#users'
              >
                <img
                  src={require('../../static/img/kintegra-favicon-without-BG.png')}
                  alt='labs'
                  width='16'
                  height='16'
                />
                Users Lists
              </Link>
            </Nav>
          </Accordion.Body>
        </Accordion.Item> */}
        <Accordion.Item eventKey='clearout'>
          <Accordion.Header>
            <img
              src={require('../../static/img/clearout_icon.png')}
              alt='Clearout'
            />
            <span>Clearout</span>
          </Accordion.Header>
          <Accordion.Body>
            <Nav className='flex-column'>
              <Link
                className='nav-link'
                to='/dashboard/clearout_users'
              >
                <img
                  src={require('../../static/img/user_lists.png')}
                  alt='Clearout'
                  width='16'
                  height='16'
                />
                Users Lists
              </Link>
              <Link
                className='nav-link'
                to='/dashboard/clearout_org_list'
              >
                <img
                  src={require('../../static/img/org_list.png')}
                  alt='Clearout'
                  width='16'
                  height='16'
                />
                Org Lists
              </Link>
              <Link
                className='nav-link'
                to={'/dashboard/clearout-email-verifier-lists'}
              >
                <img
                  src={require('../../static/img/email_verifier.png')}
                  alt='Clearout'
                  width='16'
                  height='16'
                />
                Email Verifier Lists
              </Link>
              <Link
                className='nav-link'
                to={`${OLD_CRM_URL}dashboard/clearout_emailfinder_lists`}
                target='blank'
              >
                <img
                  src={require('../../static/img/email_finder.png')}
                  alt='Clearout'
                  width='16'
                  height='16'
                />
                Email Finder Lists
              </Link>
              <Link
                className='nav-link'
                to='/dashboard/miscellaneous_api'
              >
                <img
                  src={require('../../static/img/api.png')}
                  alt='Clearout'
                  width='16'
                  height='16'
                />
                Miscellaneous API
              </Link>
              <Accordion flush>
                <Accordion.Item eventKey='prospect'>
                  <Accordion.Header>
                    <img
                      src={require('../../static/img/prospect.png')}
                      alt='Clearout'
                      width='16'
                      height='16'
                    />
                    Prospect
                  </Accordion.Header>
                  <Accordion.Body>
                    <Nav className='flex-column'>
                      <Link
                        className='nav-link'
                        to={`${OLD_CRM_URL}dashboard/clearout_prospect/lists`}
                        target='blank'
                      >
                        <img
                          src={require('../../static/img/list.png')}
                          alt='Clearout'
                          width='16'
                          height='16'
                        />
                        List
                      </Link>
                      <Link
                        className='nav-link'
                        to={`${OLD_CRM_URL}dashboard/clearout_prospect/requests`}
                        target='blank'
                      >
                        <img
                          src={require('../../static/img/request.png')}
                          alt='Clearout'
                          width='16'
                          height='16'
                        />
                        Requests
                      </Link>
                    </Nav>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
              <Accordion flush>
                <Accordion.Item eventKey='abuse-list'>
                  <Accordion.Header>
                    <img
                      src={require('../../static/img/abuse_list.png')}
                      alt='Clearout'
                      width='16'
                      height='16'
                    />
                    Abuse Lists
                  </Accordion.Header>
                  <Accordion.Body>
                    <Nav className='flex-column'>
                      <Link
                        className='nav-link'
                        to='/dashboard/clearout/abuse_list'
                      >
                        <img
                          src={require('../../static/img/manage.png')}
                          alt='Clearout'
                          width='16'
                          height='16'
                        />
                        Manage
                      </Link>
                      <Link
                        className='nav-link'
                        to='/dashboard/clearout/abuse_logs'
                      >
                        <img
                          src={require('../../static/img/view_logs.png')}
                          alt='Clearout'
                          width='16'
                          height='16'
                        />
                        View Logs
                      </Link>
                      <Link
                        className='nav-link'
                        to='/dashboard/clearout/abuse_update_logs'
                      >
                        <img
                          src={require('../../static/img/history.png')}
                          alt='Clearout'
                          width='16'
                          height='16'
                        />
                        Update History
                      </Link>
                    </Nav>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
              <Accordion flush>
                <Accordion.Item eventKey='payment'>
                  <Accordion.Header>
                    <i className="fa fa-user-secret"></i>
                    <span>Plans</span>
                  </Accordion.Header>
                  <Accordion.Body>
                    <Nav className='flex-column'>
                      <Link
                        className='nav-link'
                        to='/dashboard/clearout_plan_list'
                      >
                        <i className="fa-solid fa-tag"></i>
                        Plan List
                      </Link>
                      <Link
                        className='nav-link'
                        to='/dashboard/clearout_create_plan'
                      >
                        <i className="fa-solid fa-tag"></i>
                        Create Plan
                      </Link>
                    </Nav>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Nav>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey='clearout-phone'>
          <Accordion.Header>
            <img
              src={require('../../static/img/cop_icon.png')}
              alt='ClearoutPhone'
            />
            <span>ClearoutPhone</span>
          </Accordion.Header>
          <Accordion.Body>
            <Nav className='flex-column'>
              <Link
                className='nav-link'
                to='/dashboard/clearoutphone_users'
              >
                <img
                  src={require('../../static/img/user_lists.png')}
                  alt='ClearoutPhone'
                  width='16'
                  height='16'
                />
                Users Lists
              </Link>
              <Link
                className='nav-link'
                to={`${OLD_CRM_URL}dashboard/cop_lists`}
                target='blank'
              >
                <img
                  src={require('../../static/img/phone_number_list.png')}
                  alt='ClearoutPhone'
                  width='16'
                  height='16'
                />
                ClearoutPhone Lists
              </Link>
              <Accordion flush>
                <Accordion.Item eventKey='abuse-list'>
                  <Accordion.Header>
                    <img
                      src={require('../../static/img/abuse_list.png')}
                      alt='Clearout'
                      width='16'
                      height='16'
                    />
                    Abuse Lists
                  </Accordion.Header>
                  <Accordion.Body>
                    <Nav className='flex-column'>
                      <Link
                        className='nav-link'
                        to='/dashboard/clearoutphone/abuse_list'
                      >
                        <img
                          src={require('../../static/img/manage.png')}
                          alt='Clearout'
                          width='16'
                          height='16'
                        />
                        Manage
                      </Link>
                      <Link
                        className='nav-link'
                        to='/dashboard/clearoutphone/abuse_logs'
                      >
                        <img
                          src={require('../../static/img/view_logs.png')}
                          alt='Clearout'
                          width='16'
                          height='16'
                        />
                        View Logs
                      </Link>
                      <Link
                        className='nav-link'
                        to='/dashboard/clearoutphone/abuse_update_logs'
                      >
                        <img
                          src={require('../../static/img/history.png')}
                          alt='Clearout'
                          width='16'
                          height='16'
                        />
                        Update History
                      </Link>
                    </Nav>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Nav>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey='spokesly'>
          <Accordion.Header>
            <img
              src={require('../../static/img/spokesly-icon.png')}
              alt='Spokesly'
            />
            <span>Spokesly</span>
          </Accordion.Header>
          <Accordion.Body>
            <Nav className='flex-column'>
              <Link
                className='nav-link'
                to={`${OLD_CRM_URL}dashboard/spokesly_users`}
                target='blank'
              >
                <img
                  src={require('../../static/img/spokesly-icon.png')}
                  alt='Spokesly'
                  width='16'
                  height='16'
                />
                Users Lists
              </Link>
              <Link
                className='nav-link'
                to={`${OLD_CRM_URL}dashboard/spokesly_clearout_org_list`}
                target='blank'
              >
                <img
                  src={require('../../static/img/spokesly-icon.png')}
                  alt='Spokesly'
                  width='16'
                  height='16'
                />
                Org Lists
              </Link>
              <Link
                className='nav-link'
                to={`${OLD_CRM_URL}dashboard/spokesly_list`}
                target='blank'
              >
                <img
                  src={require('../../static/img/spokesly-icon.png')}
                  alt='Spokesly'
                  width='16'
                  height='16'
                />
                Email Verifier Lists
              </Link>
              <Link
                className='nav-link'
                to={`${OLD_CRM_URL}dashboard/spokesly_clearout_emailfinder_lists`}
                target='blank'
              >
                <img
                  src={require('../../static/img/spokesly-icon.png')}
                  alt='Spokesly'
                  width='16'
                  height='16'
                />
                Email FInder Lists
              </Link>
            </Nav>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey='clearout-eu'>
          <Accordion.Header>
            <img
              src={require('../../static/img/CO-EU.png')}
              alt='Clearout EU'
            />
            <span>Clearout EU</span>
          </Accordion.Header>
          <Accordion.Body>
            <Nav className='flex-column'>
              <Link
                className='nav-link'
                to={`${OLD_CRM_URL}dashboard/clearout_eu_users`}
                target='blank'
              >
                <img
                  src={require('../../static/img/CO-EU.png')}
                  alt='ClearoutEU'
                  width='16'
                  height='16'
                />
                Users Lists
              </Link>
              <Link
                className='nav-link'
                to={`${OLD_CRM_URL}dashboard/clearout_eu_org_list`}
                target='blank'
              >
                <img
                  src={require('../../static/img/CO-EU.png')}
                  alt='ClearoutEU'
                  width='16'
                  height='16'
                />
                Org Lists
              </Link>
              <Link
                className='nav-link'
                to={`${OLD_CRM_URL}dashboard/clearout_eu_list`}
                target='blank'
              >
                <img
                  src={require('../../static/img/CO-EU.png')}
                  alt='ClearoutEU'
                  width='16'
                  height='16'
                />
                Email Verifier Lists
              </Link>
              <Link
                className='nav-link'
                to={`${OLD_CRM_URL}dashboard/clearout_eu_emailfinder_lists`}
                target='blank'
              >
                <img
                  src={require('../../static/img/CO-EU.png')}
                  alt='ClearoutEU'
                  width='16'
                  height='16'
                />
                Email Finder Lists
              </Link>
            </Nav>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey='clearout-ep'>
          <Accordion.Header>
            <img
              src={require('../../static/img/CO-EP.png')}
              alt='Clearout EP'
            />
            <span>Clearout EP</span>
          </Accordion.Header>
          <Accordion.Body>
            <Nav className='flex-column'>
              <Link
                className='nav-link'
                to={`${OLD_CRM_URL}dashboard/clearout_ep_users`}
                target='blank'
              >
                <img
                  src={require('../../static/img/CO-EP.png')}
                  alt='ClearoutEP'
                  width='16'
                  height='16'
                />
                Users Lists
              </Link>
              <Link
                className='nav-link'
                to={`${OLD_CRM_URL}dashboard/clearout_ep_org_list`}
                target='blank'
              >
                <img
                  src={require('../../static/img/CO-EP.png')}
                  alt='ClearoutEP'
                  width='16'
                  height='16'
                />
                Org Lists
              </Link>
              <Link
                className='nav-link'
                to={`${OLD_CRM_URL}dashboard/clearout_ep_list`}
                target='blank'
              >
                <img
                  src={require('../../static/img/CO-EP.png')}
                  alt='ClearoutEP'
                  width='16'
                  height='16'
                />
                Email Verifier Lists
              </Link>
              <Link
                className='nav-link'
                to={`${OLD_CRM_URL}dashboard/clearout_ep_emailfinder_lists`}
                target='blank'
              >
                <img
                  src={require('../../static/img/CO-EP.png')}
                  alt='ClearoutEP'
                  width='16'
                  height='16'
                />
                Email Finder Lists
              </Link>
            </Nav>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey='coupons'>
          <Accordion.Header>
            <i className="fa-solid fa-tags"></i>
            <span>Coupons</span>
          </Accordion.Header>
          <Accordion.Body>
            <Nav className='flex-column'>
              <Link
                className='nav-link'
                to='/dashboard/clearout/coupons'
                target='blank'
              >
                <i className="fa-solid fa-tag"></i>
                Clearout Coupons
              </Link>
              <Link
                className='nav-link'
                to='/dashboard/clearoutphone/coupons'
                target='blank'
              >
                <i className="fa-solid fa-tag"></i>
                ClearoutPhone Coupons
              </Link>
            </Nav>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </aside>
  )
}

export default SideBar