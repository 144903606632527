
export const API_HELPERS = {
  clearouts: {
    USER_LIST: {
      API_NAME: 'getOrganizationLists',
      API_BODY: ['skip', 'limit', 'search', 'sort', 'filters']
    },
    MEMBERS_LIST: {
      API_NAME: 'getClearoutOrgUsersList',
      API_BODY: ['skip', 'limit', 'search', 'sort', 'filters', 'org_id']
    },
  },
}