import React, { useState, useEffect } from 'react';
import * as Constants from '../../helpers/constants';
import * as Helper from '../../helpers/helper';

const ResultTable = ({ metrics: initialMetrics, resultsArray: initialResultsArray, lastVerifiedOn: initialLastVerifiedOn, status }) => {
  const [metrics, setMetrics] = useState(initialMetrics);
  const [resultsArray, setResultsArray] = useState(initialResultsArray);
  const [lastVerifiedOn, setLastVerifiedOn] = useState(initialLastVerifiedOn);

  useEffect(() => {
    setMetrics(initialMetrics);
    setResultsArray(initialResultsArray);
    setLastVerifiedOn(initialLastVerifiedOn);
  }, [initialMetrics, initialResultsArray, initialLastVerifiedOn]);

  const showTitleBasedOnStatus = () => {
    if (lastVerifiedOn && status === Constants.LIST_STATUSES.CANCELLED) {
      return (<span style={{ color: '#ff4c19' }}>Cancelled Result</span>);
    }
    return lastVerifiedOn ? 'Verified Result' : 'Analysis Result';
  };

  const isAnalysisMetricsExists = resultsArray.some(r => Constants.ANALYSIS_TABLE_DISPLAY_ORDER.indexOf(r) >= 0);

  return (
    <div className="analysis-result-container">
      <div className="m-0 ">
        {showTitleBasedOnStatus()}
      </div>
      <table className="analysis-info-table table table-borderless table-sm">
        <tbody>
          <tr className="analysis-table-tr">
            <td className="valid-info-table">
              <table className="table table-borderless">
                <tbody>
                  {resultsArray.map((result, index) => {
                    if (Constants.TOP_RESULTS_TABLE_DISPLAY_ORDER.includes(result)) {
                      return (
                        <tr key={index}>
                          <td className={`${result}-result`}>{Constants.RESULTS[result].display_name}</td>
                          <td className="result-value">
                            {Helper.getResultAsReqFormat(result, metrics[result])}
                          </td>
                        </tr>
                      );
                    }
                    return null;
                  })}
                </tbody>
              </table>
            </td>
            {isAnalysisMetricsExists ? (
              <td className="analysis-tab-info">
                <table className="table table-borderless">
                  <tbody>
                    {resultsArray.map((result, index) => {
                      if (Constants.ANALYSIS_TABLE_DISPLAY_ORDER.includes(result)) {
                        return (
                          <tr key={index}>
                            <td className={`${result}-result`}>{Constants.RESULTS[result].display_name}</td>
                            <td className="result-value">
                              {Helper.getResultAsReqFormat(result, metrics[result])}
                            </td>
                          </tr>
                        );
                      }
                      return null;
                    })}
                  </tbody>
                </table>
              </td>
            ) : null}
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default ResultTable;
