import React, { useCallback, useEffect, useState } from 'react'
import classNames from 'classnames'
import moment from 'moment'

import UserSubscriptionsTable from '../../../../tables/subscription_history/user_subscriptions'
import * as Constants from '../../../../../helpers/constants'
import ApiService from '../../../../../helpers/apiservice'
import { formatTime } from '../../../../../helpers/helper'

const initialState = {
  userSubscriptions: []
}

const CopUserSubscription = ({ userData }) => {
  const [state, setState] = useState(initialState)

  setTimeout(() => {
    console.log(state.userSubscriptions)
  }, 3000);

  const getUsersSubscriptions = useCallback(() => {
    let body = {
      client_secret: Constants.CLIENT_SECRET,
      sort: { '_id': 'desc' },
      filters: { user_id: userData.user_id, status: { $ne: 'approval_pending' } }
    }
    ApiService.clearoutPhoneUserSubsList(body)
      .then((response) => {
        setState(prevState => ({
          ...prevState,
          userSubscriptions: response.data
        }))
      }
      )
      .catch((err) => {
        console.log(err)
      })
  }, [userData.user_id])

  //component mount
  useEffect(() => {
    getUsersSubscriptions()
  }, [getUsersSubscriptions])

  const columns = [
    {
      label: 'Plan',
      renderCell: (item) => {
        console.log(item.plan_name)
        return (
          <div className='d-flex flex-column'>
            <span className='fw-bold'>{item.plan_name}</span>
            <span>{'Plan Id:'}&nbsp;{item.plan_id}</span>
            <span className={classNames('text-capitalize')}>{'Type: '}&nbsp;{item.plan_type === 'yearly' ? 'annually' : 'monthly'}</span>
            <span className={classNames('text-capitalize', {
              'text-success': item.status === 'active',
              'text-danger': item.status !== 'active'
            })}
            >
              {item.status}
            </span>
            {
              ['cancelled', 'expired'].includes(item.status) &&
                item.cancel_reason
                ?
                <>
                  <span className='text-wrap'>{'Reason: '}{item.cancel_reason}</span>
                </>
                : null
            }
          </div>
        )
      },
    },
    {
      label: 'Info',
      renderCell: (userProduct) => {
        return (
          <div className='d-flex flex-column'>
            <span>{'Starts On: '}{moment(userProduct.start_time).format('Do MMMM YYYY, h:mm:ss A')}</span>
            <span>{'Ends On: '}{moment(userProduct.end_time).format('Do MMMM YYYY, h:mm:ss A')}</span>
            <span>{'Created On: '}{moment(userProduct.created_on).format('Do MMMM YYYY, h:mm:ss A')}</span>
            <span>{'Modified On: '}{moment(userProduct.modified_on).format('Do MMMM YYYY, h:mm:ss A')}</span>
          </div>
        )
      },
    },
    {
      label: 'Paypal',
      renderCell: (item) => {
        return (
          <div className='d-flex flex-column'>
            <span>{'Subs Id: '}<span className='fw-bold'>{item.subs_details.paypal ? item.subs_details.paypal.id : '-'}</span></span>
            <span>{'Plan Id: '}<span className='fw-bold'>{item.subs_details.paypal ? item.subs_details.paypal.plan_id : '-'}</span></span>
            <span>{'Subscriber Email: '}<span className='fw-bold'>{item.subs_details.paypal ? item.subs_details.paypal.subscriber.email_address : '-'}</span></span>
          </div>
        )
      }
    },
    {
      label: 'Next Dates',
      renderCell: (userProduct) => {
        return (
          <div className='d-flex flex-column'>
            <>
              <span>{'Next Billing Date: '}{userProduct.subs_details.paypal ? formatTime(
                userProduct.subs_details.paypal.billing_info.next_billing_time,
                'DD MMM YYYY hh:mm A'
              ) : '-'}
              </span>
            </>
          </div>
        )
      },
    },
  ];

  return (
    <div>
      <UserSubscriptionsTable
        data={state.userSubscriptions}
        columns={columns}
      />
    </div>
  )
}

export default CopUserSubscription