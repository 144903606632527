import React, { useState, useCallback, useRef,useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Dropdown, { MenuItem } from '@trendmicro/react-dropdown';
import { Badge, Button } from 'react-bootstrap';
import useOnClickOutside from '../hooks/on_click_outside_div';

import '@trendmicro/react-buttons/dist/react-buttons.css';
import '@trendmicro/react-dropdown/dist/react-dropdown.css';
import '../../static/css/search.css';

import * as Constants from '../../helpers/constants';

const Filters = ({
  setFilter,
  ...props
}) => {
  const location = useLocation();

  // State for filters
  const [selectedTypeFilter, setSelectedTypeFilter] = useState({
    label: 'All',
    value: 'all',
    filter_label: 'Source'
  });
  const [selectedDateRange, setSelectedDateRange] = useState({
    value: 'ps_last_7_days_including_today',
    label: 'Last 7 Days (Including Today)',
    filter_label: 'Created On'
  });
  const [verifiedListFilter, setVerifiedListFilter] = useState({
    label: 'In Progress',
    value: 'in_progress',
    filter_label: 'Status'
  });
  const filterState = {
    selectedTypeFilter,
    selectedDateRange,
    verifiedListFilter
  };
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  // Dropdown open state
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  // Ref for dropdown to handle clicks outside
  const filterDropdownRef = useRef(null);

  // Click outside to close dropdown
  useOnClickOutside(filterDropdownRef, () => setIsDropdownOpen(false));

  // Initial filter state
  const initialState = {
    selectedTypeFilter: {
      label: 'All',
      value: 'all',
      filter_label: 'Source'
    },
    selectedDateRange: {
      value: 'ps_last_7_days_including_today',
      label: 'Last 7 Days (Including Today)',
      filter_label: 'Created On'
    },
    verifiedListFilter: {
      label: 'In Progress',
      value: 'in_progress',
      filter_label: 'Status'
    },
    showErrorMessage: false
  };

  // Set filter params based on selected filters
  const setParams = useCallback((newSelectedTypeFilter) => {
    let filters = {
      type: newSelectedTypeFilter.value,
      date_filter: { key: 'created_on', preset: selectedDateRange.value }
    };

    switch (verifiedListFilter.value) {
      case 'verified':
        filters.verified = 'verified';
        break;
      case 'non_verified':
        filters.verified = 'non_verified';
        break;
      case 'in_progress':
        filters.verified = 'in_progress';
        break;
      case 'cancelled':
        filters.verified = 'cancelled';
        break;
      default:
        filters.verified = null;
    }

    if (filters.type === 'all' || filters.type === '') {
      delete filters.type;
    }

    props.filterParams(filters);
  }, [selectedDateRange.value, verifiedListFilter.value, props,]);
  
  const updateFilter = useCallback((newFilterProperties) => {
    setFilter((prevFilter) => {
    // Create a new filters object, spreading the existing one
      const updatedFilters = { ...prevFilter.filters, ...newFilterProperties };

      // Remove any properties that have a value of `null`
      Object.keys(updatedFilters).forEach(key => {
        if (updatedFilters[key] === null) {
          delete updatedFilters[key];
        }
      });

      return {
        ...prevFilter,
        filters: updatedFilters,
        currentPageIndex: 0
      };
    });
  }, [setFilter]);

  // Selector for filters
  const onSelectSource = useCallback((type) => {
    if (type) {
      let selectedType = Constants.LIST_TYPES[type];
      selectedType.filter_label = 'Source';
      setShowErrorMessage(false);
      setSelectedTypeFilter(selectedType);
      setIsDropdownOpen(false);

      // If selected type is 'all', pass `null` to remove the `type` property
      const filterValue = selectedType.value === 'all' ? null : selectedType.value;
      updateFilter({ type: filterValue });
    }
  }, [updateFilter]);


  useEffect(() => {
    //setParams();
    let params = new URLSearchParams(location.search);
    if (params && params.get('source')) {
      onSelectSource(params.get('source'));
    }
  }, [onSelectSource,location.search, setParams]);

  const onSelectDateFilter = (eventKey) => {
    if (eventKey) {
      let datePreset = {
        label: Constants.DATERANGE_PRESETS.date_range_presets_definitions[eventKey].display_name,
        value: eventKey,
        filter_label: 'Created On'
      };
      setSelectedDateRange(datePreset);
      setShowErrorMessage(false);
      setIsDropdownOpen(false);
      updateFilter({date_filter:{key: 'created_on',preset: datePreset.value}})
    }
  };

  const onSelectVerifiedFilter = (eventKey) => {
    if (eventKey) {
      let verifiedFilter = Constants.VERIFIED_TYPES[eventKey];
      verifiedFilter.filter_label = 'Status';
      setVerifiedListFilter(verifiedFilter);
      setShowErrorMessage(false);
      setIsDropdownOpen(false);
      updateFilter({verified: verifiedFilter.value})
    }
  };

  // Populate badges for selected filters
  const showLabels = () => {
    let filters = ['selectedTypeFilter', 'selectedDateRange', 'verifiedListFilter'];
    return filters.map((filter, index) => {
      const filterData = filterState[filter];
      if (filterData && filterData.value) {
        return (
          <Badge
            key={index}
            color="default"
            className="filter-badge"
          >
            {filterData.filter_label ? filterData.filter_label + ': ' : null}
            <span>{filterData.label}</span>
            <span
              className="close-icon"
              onClick={() => onRemoveFilter(filter)}
            >
            </span>
          </Badge>
        );
      }
      return null;
    });
  };

  // Remove a specific filter
  const onRemoveFilter = (filter) => {
    let reset = { value: '', label: '' };
    switch (filter) {
      case 'selectedTypeFilter':
        setSelectedTypeFilter(reset);
        updateFilter({
          type: null,
        })
        break;
      case 'selectedDateRange':
        setSelectedDateRange(reset);
        updateFilter({
          date_filter: null,
        })
        break;
      case 'verifiedListFilter':
        setVerifiedListFilter(reset);
        updateFilter({
          verified: null
        })
        break;
      default:
        break;
    }
  };

  // Reset filters to initial state
  const resetFilters = () => {
    setSelectedTypeFilter(initialState.selectedTypeFilter);
    setSelectedDateRange(initialState.selectedDateRange);
    setVerifiedListFilter(initialState.verifiedListFilter);
    setShowErrorMessage(initialState.showErrorMessage);
    updateFilter({
      type: null,
      date_filter: { key: 'created_on', preset: 'ps_last_7_days_including_today' },
      verified: 'in_progress'
    })
  };

  // Toggle dropdown open/close
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <div className="lists-filters">
      <div
        className="d-flex"
        ref={filterDropdownRef}
      >
        <div className="filter-comp">
          <Dropdown
            open={isDropdownOpen}
            onToggle={() => {}}
          >
            <Dropdown.Toggle onClick={toggleDropdown}>
              <i className="fa fa-filter"></i> Filters
            </Dropdown.Toggle>
            {isDropdownOpen && (
              <Dropdown.Menu>
                <MenuItem
                  className="filter-menu-item"
                  onSelect={onSelectVerifiedFilter}
                >
                  Status
                  <MenuItem
                    className="filter-menu-item"
                    eventKey="verified"
                  >Verified
                  </MenuItem>
                  <MenuItem
                    className="filter-menu-item"
                    eventKey="non_verified"
                  >Non - Verified
                  </MenuItem>
                  <MenuItem
                    className="filter-menu-item"
                    eventKey="in_progress"
                  >In - Progress
                  </MenuItem>
                  <MenuItem
                    className="filter-menu-item"
                    eventKey="cancelled"
                  >Cancelled
                  </MenuItem>
                </MenuItem>
                <MenuItem
                  className="filter-menu-item"
                  onSelect={onSelectDateFilter}
                >
                  Created On
                  {Constants.DATERANGE_PRESETS.date_range_presets.map((preset) => (
                    <MenuItem
                      key={preset}
                      eventKey={Constants.DATERANGE_PRESETS.date_range_presets_definitions[preset].value}
                    >
                      {Constants.DATERANGE_PRESETS.date_range_presets_definitions[preset].display_name}
                    </MenuItem>
                  ))}
                </MenuItem>
                <MenuItem
                  className="filter-menu-item"
                >
                  Source
                  {Object.keys(Constants.LIST_TYPES).map((list) => (
                    <MenuItem
                      key={list}
                      eventKey={Constants.LIST_TYPES[list].value}
                      onSelect={()=> onSelectSource(Constants.LIST_TYPES[list].value)}
                    >
                      {Constants.LIST_TYPES[list].label}
                    </MenuItem>
                  ))}
                </MenuItem>
              </Dropdown.Menu>
            )}
          </Dropdown>
          <div className="list-selected-filters">{showLabels()}</div>
        </div>
        <Button
          className="filter-reset-btn"
          onClick={resetFilters}
        >Reset
        </Button>
      </div>
      {showErrorMessage && <p className="error-message">Please select default filters of date and source</p>}
    </div>
  );
};

export default Filters;
