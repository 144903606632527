
export const API_HELPERS = {
  clearout: {
    USER_INFO: {
      API_NAME: 'getCleaoutUserInfo',
      API_BODY: ['co_user_id']
    },
    ADDITIONAL_INFO: {
      API_NAME: 'getClearoutUserProfileAdditionalInfo',
      API_BODY: ['co_user_id']
    },
    ADD_BONUS: {
      API_NAME: 'giveBonusClearoutUser',
      API_BODY: ['co_user_id', 'user_id', 'credits', 'description']
    },
    RESET_BONUS: {
      API_NAME: 'resetBonusClearoutUser',
      API_BODY: ['co_user_id', 'user_id', 'description']
    },
    UPDATE_TIMEZONE: {
      API_NAME: 'clearoutUpdateTimezone',
      API_BODY: ['co_user_id', 'timezone']
    },
    BLOCK_USER: {
      API_NAME: 'blockClearoutUser',
      API_BODY: ['co_user_id', 'user_id', 'reason']
    },
    LOGOUT_SESSIONS: {
      API_NAME: 'logoutAllSessionOfClearoutUser',
      API_BODY: ['co_user_id', 'session_type']
    },
    UNBLOCK_USER: {
      API_NAME: 'unblockClearoutUser',
      API_BODY: ['co_user_id', 'user_id', 'reason']
    },
    FORGOT_PASSWORD_LINK: {
      API_NAME: 'resetTokens',
      API_BODY: ['co_user_id', 'action_type']
    },
    SIGN_UP_LINK: {
      API_NAME: 'resetTokens',
      API_BODY: ['co_user_id', 'action_type']
    },
    UPDATE_API_RATE_LIMIT: {
      API_NAME: 'updateRateLimitIndividual',
      API_BODY: ['api_rate_limits', 'co_user_id', 'reason', 'user_id']
    },
    REMOVE_API_RATE_LIMIT: {
      API_NAME: 'removeRateLimitIndividual',
      API_BODY: ['api_names', 'co_user_id', 'reason', 'user_id']
    },
    ADD_DAILY_LIMIT: {
      API_NAME: 'clearoutAddDailyLimit',
      API_BODY: ['co_user_id', 'user_id', 'daily_verify_limit', 'reason']
    },
    REMOVE_DAILY_LIMIT: {
      API_NAME: 'clearoutUsersRemoveDailyLimit',
      API_BODY: ['co_user_id', 'affiliate', 'user_id']
    },
    UPDATE_DAILY_LIMIT: {
      API_NAME: 'clearoutUpdateDailyLimit',
      API_BODY: ['co_user_id', 'user_id', 'daily_verify_limit', 'reason']
    },
    ALLOWED_DAILY_LIMIT: {
      API_NAME: 'clearoutAddDailyLimit',
      API_BODY: ['co_user_id', 'user_id', 'daily_verify_limit', 'reason']
    },
    RECEIPTS: {
      API_NAME: 'listInvoices',
      API_BODY: ['co_user_id']
    },
    RADAR: {
      API_NAME: 'toggleRadar',
      API_BODY: ['co_user_id', 'user_id', 'reason', 'radarStatus']
    },
    GENERATE_RECEIPT: {
      API_NAME: 'clearoutMarkAsPaid',
      API_BODY: ['co_user_id', 'user_id', 'amount', 'payment_type', 'credits', 'payment_gateway', 'description', 'client_secret']
    },
    EDIT_EMAIL: {
      API_NAME: 'clearoutUpdateUserEmail',
      API_BODY: ['co_user_id', 'user_id', 'email']
    },
    DELETE_ACCOUNT: {
      API_NAME: 'deleteClearoutUser',
      API_BODY: ['co_user_id', 'reason', 'user_id']
    },
    USER_PLANS_AND_ACTIVE_SUBSCRIPTIONS: {
      API_NAME: 'getUsersPlansAndActiveSubscriptions',
      API_BODY: ['co_user_id', 'filters']
    },
    UPDATE_USERS_PAYMENT_SETTINGS: {
      API_NAME: 'udpateUserPaymentSettings',
      API_BODY: ['co_user_id', 'acap', 'alap']
    },
    UPDATE_USERS_DEFAULT_PAYMENT_ACCOUNT: {
      API_NAME: 'udpateUserDefaultPaymentAccount',
      API_BODY: ['co_user_id', 'stripe_account', 'paypal_account']
    },
    GET_COUNTRIES: {
      API_NAME: 'getAbuseListWrtCountry',
      API_BODY: ['country']
    },
    ADD_ABUSE_ENTRY: {
      API_NAME: 'addAbuseListEntry',
      API_BODY: ['type', 'value', 'reason', 'block_free_email', 'user_id']
    },
    SIGN_UP_ABUSE_LIMITTER: {
      API_NAME: 'signUpAbuseLimiter',
      API_BODY: ['domain', 'ip', 'action', 'email', 'user_id']
    },
    UPDATE_ABUSE_ENTRY: {
      API_NAME: 'updateAbuseListEntry',
      API_BODY: ['type', 'value', 'reason', 'block_free_email', 'abuse_id', 'exempted', 'user_id']
    },
    GET_ABUSE_LIST: {
      API_NAME: 'getAbuseList',
      API_BODY: ['skip', 'limit', 'search', 'sort', 'abuse_id']
    },
    COUPONS_LIST: {
      API_NAME: 'listCoupons',
      API_BODY: ['skip', 'limit', 'search', 'sort']
    },
    CREATE_COUPONS: {
      API_NAME: 'createCoupon',
      API_BODY: ['name', 'coupon', 'expiry', 'status', 'user_id', 'description', 'affiliated_to', 'coupon_type', 'allowed_redeem_count',
        'discount_type', 'discount_min', 'discount_max', 'discount_value', 'credits', 'daily_verify_limit']
    },
    UPDATE_COUPONS: {
      API_NAME: 'editCoupon',
      API_BODY: ['name', 'coupon', 'expiry', 'status', 'user_id', 'description', 'affiliated_to', 'coupon_type', 'allowed_redeem_count',
        'discount_type', 'discount_min', 'discount_max', 'discount_value', 'credits', 'daily_verify_limit']
    },
    ABUSE_LOGS: {
      API_NAME: 'getClearoutAbuseActivityLogs',
      API_BODY: ['skip', 'limit', 'search', 'sort', 'filters']
    },
    GET_ABUSE_UPDATE_ACTIVITY: {
      API_NAME: 'getClearoutAbuseUpdateLogs',
      API_BODY: ['skip', 'limit', 'search', 'sort', 'filters']
    },
    USER_SUBSCRIPTIONS: {
      API_NAME: 'getUserSubscriptions',
      API_BODY: ['co_user_id']
    },
    USER_ACTIVITY: {
      API_NAME: 'getClearoutUsersActivities',
      API_BODY: ['co_user_id', 'skip', 'limit', 'search', 'sort', 'filters']
    },
    MEMBERS_LIST: {
      API_NAME: 'getClearoutOrgUsersList',
      API_BODY: ['skip', 'limit', 'search', 'sort', 'filters', 'org_id']
    },
  },
  clearoutphone: {
    USER_INFO: {
      API_NAME: 'getCopUserInfo',
      API_BODY: ['client_secret', 'user_id']
    },
    ADDITIONAL_INFO: {
      API_NAME: 'getClearoutPhoneUserProfileAdditionalInfo',
      API_BODY: ['client_secret', 'cop_user_id']
    },
    ADD_BONUS: {
      API_NAME: 'giveBonusCopUser',
      API_BODY: ['client_secret', 'cop_user_id', 'created_by', 'credits', 'description', 'crm_user_data']
    },
    RESET_BONUS: {
      API_NAME: 'resetBonusCopUser',
      API_BODY: ['client_secret', 'cop_user_id', 'created_by', 'description', 'crm_user_data']
    },
    UPDATE_TIMEZONE: {
      API_NAME: 'clearoutPhoneUpdateTimezone',
      API_BODY: ['cop_user_id', 'timezone', 'client_secret', 'crm_user_data']
    },
    SIGN_UP_LINK: {
      API_NAME: 'copResetTokens',
      API_BODY: ['user_id', 'action_type', 'client_secret', 'crm_user_id', 'crm_user_data']
    },
    FORGOT_PASSWORD_LINK: {
      API_NAME: 'copResetTokens',
      API_BODY: ['user_id', 'action_type', 'client_secret', 'crm_user_id', 'crm_user_data']
    },
    LOGOUT_SESSIONS: {
      API_NAME: 'logoutCopAllSessionOfClearoutUser',
      API_BODY: ['cop_user_id', 'session_type', 'client_secret', 'crm_user_data']
    },
    UPDATE_API_RATE_LIMIT: {
      API_NAME: 'updateRateLimitForCOPIndividual',
      API_BODY: ['api_rate_limits', 'cop_user_id', 'reason', 'user_id', 'client_secret', 'crm_user']
    },
    REMOVE_API_RATE_LIMIT: {
      API_NAME: 'removeRateLimitForCOPIndividual',
      API_BODY: ['api_names', 'cop_user_id', 'reason', 'user_id', 'client_secret', 'crm_user']
    },
    ADD_DAILY_LIMIT: {
      API_NAME: 'clearoutPhoneAddDailyLimit',
      API_BODY: ['cop_user_id', 'crm_user_id', 'daily_verify_limit', 'reason', 'client_secret', 'crm_user_data']
    },
    REMOVE_DAILY_LIMIT: {
      API_NAME: 'clearoutPhoneUsersRemoveDailyLimit',
      API_BODY: ['cop_user_id', 'crm_user_id', 'affiliate', 'client_secret', 'crm_user_data']
    },
    UPDATE_DAILY_LIMIT: {
      API_NAME: 'clearoutPhoneUpdateDailyLimit',
      API_BODY: ['cop_user_id', 'crm_user_id', 'daily_verify_limit', 'client_secret', 'crm_user_data', 'reason']
    },
    ALLOWED_DAILY_LIMIT: {
      API_NAME: 'clearoutPhoneAddDailyLimit',
      API_BODY: ['cop_user_id', 'crm_user_id', 'daily_verify_limit', 'reason', 'client_secret', 'crm_user_data']
    },
    RECEIPTS: {
      API_NAME: 'listCopInvoices',
      API_BODY: ['client_secret', 'cop_user_id']
    },
    GENERATE_RECEIPT: {
      API_NAME: 'clearoutPhoneMarkAsPaid',
      API_BODY: ['cop_user_id', 'user_id', 'amount', 'payment_type', 'credits', 'payment_gateway', 'description', 'client_secret']
    },
    EDIT_EMAIL: {
      API_NAME: 'clearoutPhoneUpdateUserEmail',
      API_BODY: ['cop_user_id', 'user_id', 'email', 'client_secret']
    },
    BLOCK_USER: {
      API_NAME: 'blockClearoutPhoneUser',
      API_BODY: ['cop_user_id', 'user_id', 'reason', 'client_secret', 'crm_user_data']
    },
    UNBLOCK_USER: {
      API_NAME: 'unBlockClearoutPhoneUser',
      API_BODY: ['cop_user_id', 'user_id', 'reason', 'client_secret', 'crm_user_data']
    },
    DELETE_ACCOUNT: {
      API_NAME: 'deleteClearoutPhoneUser',
      API_BODY: ['cop_user_id', 'reason', 'client_secret', 'crm_user_id']
    },
    CREATE_COUPONS: {
      API_NAME: 'createCopCoupon',
      API_BODY: ['name', 'coupon', 'expiry', 'status', 'user_id', 'description', 'affiliated_to', 'coupon_type', 'allowed_redeem_count',
        'discount_type', 'discount_min', 'discount_max', 'discount_value', 'client_secret', 'credits', 'daily_verify_limit']
    },
    UPDATE_COUPONS: {
      API_NAME: 'editCopCoupon',
      API_BODY: ['name', 'coupon', 'expiry', 'status', 'user_id', 'description', 'affiliated_to', 'coupon_type', 'allowed_redeem_count',
        'discount_type', 'discount_min', 'discount_max', 'discount_value', 'client_secret', 'credits', 'daily_verify_limit']
    },
    GET_ABUSE_LIST: {
      API_NAME: 'getCopAbuseList',
      API_BODY: ['skip', 'limit', 'search', 'sort', 'abuse_id', 'client_secret']
    },
    ADD_ABUSE_ENTRY: {
      API_NAME: 'addCopAbuseListEntry',
      API_BODY: ['type', 'value', 'reason', 'block_free_email', 'client_secret', 'crm_user_data']
    },
    GET_COUNTRIES: {
      API_NAME: 'getCopAbuseListWrtCountry',
      API_BODY: ['country', 'client_secret']
    },
    SIGN_UP_ABUSE_LIMITTER: {
      API_NAME: 'copSignUpAbuseLimiter',
      API_BODY: ['domain', 'ip', 'action', 'email', 'crm_user_data', 'client_secret']
    },
    UPDATE_ABUSE_ENTRY: {
      API_NAME: 'updateCopAbuseListEntry',
      API_BODY: ['type', 'value', 'reason', 'block_free_email', 'abuse_id', 'exempted', 'client_secret', 'crm_user_data']
    },
    ABUSE_LOGS: {
      API_NAME: 'getClearoutPhoneAbuseActivityLogs',
      API_BODY: ['skip', 'limit', 'search', 'sort', 'filters', 'client_secret']
    },
    GET_ABUSE_UPDATE_ACTIVITY: {
      API_NAME: 'getClearoutPhoneAbuseUpdateLogs',
      API_BODY: ['skip', 'limit', 'search', 'sort', 'filters', 'client_secret']
    },
    USER_ACTIVITY: {
      API_NAME: 'getCopUsersActivities',
      API_BODY: ['co_user_id', 'skip', 'limit', 'search', 'sort', 'filters', 'client_secret']
    },
  }
}