
export const USER_LIST_TABLE_COLUMNS = {
  clearout: [
    {
      header: 'Sr No.',
      accessorKey: 'sr_no',
      cellFormatter: 'srNoFormater',
    },
    {
      header: 'Name',
      accessorKey: 'name',
      cellFormatter: 'coNameFormatter',
    },
    {
      header: 'Email',
      accessorKey: 'email',
      cellFormatter: 'coEmailFormatter',
    },
    {
      header: 'Phones',
      accessorKey: 'phone_number',
      cellFormatter: 'coPhoneNumberFormatter',
    },
    {
      header: 'Credits',
      accessorKey: 'credits',
      cellFormatter: 'creditsFormatter',
    },
    {
      header: 'Signup Type',
      accessorKey: 'additionalInfo',
      cellFormatter: 'coSignUpFormatter',
    },
    {
      header: 'Recur',
      accessorKey: 'recur',
      cellFormatter: 'recurFormatter',
    },
    {
      header: 'Subscription',
      accessorKey: 'subscription',
      cellFormatter: 'coSubsFormatter',
    },
    {
      header: 'Last Subscription',
      accessorKey: 'last_subscription',
      cellFormatter: 'coLastSubsFormatter',
    },
    {
      header: 'ThresHold',
      accessorKey: 'settings',
      cellFormatter: 'thresHoldFormatter',
    },
    {
      header: 'Role',
      accessorKey: 'user_role',
      cellFormatter: 'userRoleFormatter',
    },
    {
      header: 'User Source',
      accessorKey: 'user_source',
      cellFormatter: 'userSourceFormatter',
    },
    {
      header: 'Channel Source',
      accessorKey: 'channel_source',
      cellFormatter: 'channelSourceFormatter',
    },
    {
      header: 'Channel Source Campaign',
      accessorKey: 'channel_source',
      cellFormatter: 'channelSourceCampaignFormatter',
    },
    {
      header: 'Channel Source Query',
      accessorKey: 'channel_source',
      cellFormatter: 'channelSourceQueryFormatter',
    },
    {
      header: 'Affiliate',
      accessorKey: 'affiliate',
      cellFormatter: 'affiliateFormatter',
    },
    {
      header: 'Last Purchased On',
      accessorKey: 'payment_info',
      cellFormatter: 'coLastPurchasedFormatter',
    },
    {
      header: 'Total Amount Paid (in USD)',
      accessorKey: 'payment_info',
      cellFormatter: 'coTotalAmountPaidFormatter',
    },
    {
      header: 'Payment Count',
      accessorKey: 'payment_info',
      cellFormatter: 'coPaymentCountFormatter',
    },
    {
      header: 'Sign Up IP',
      accessorKey: 'ip',
      cellFormatter: 'signUpIPFormatter',
    },
    {
      header: 'Sign UP Country',
      accessorKey: 'country',
      cellFormatter: 'signUpCountryFormatter',
    },
    {
      header: 'Activated By',
      accessorKey: 'additionalInfo.value.activation_link_type',
      cellFormatter: 'coActivatedByFormatter',
    },
    {
      header: 'Activated On',
      accessorKey: 'activated_on',
      cellFormatter: 'activatedOnFormatter',
    },
    {
      header: 'Created On',
      accessorKey: 'created_on',
      cellFormatter: 'createdOnFormatter',
    },
    {
      header: 'Account Delete Reason',
      accessorKey: 'deleted_reason',
      cellFormatter: 'coAccountDeleteReasonFormat',
    },
    {
      header: 'User Id',
      accessorKey: '_id',
      cellFormatter: 'userIdFormatter',
    },
  ],
  clearoutphone: [
    {
      header: 'Sr No.',
      accessorKey: 'sr_no',
      cellFormatter: 'srNoFormater',
    },
    {
      header: 'Name',
      accessorKey: 'name',
      cellFormatter: 'copNameFormatter',
    },
    {
      header: 'Email',
      accessorKey: 'email',
      cellFormatter: 'copEmailFormatter',
    },
    {
      header: 'Credits',
      accessorKey: 'credits',
      cellFormatter: 'creditsFormatter',
    },
    {
      header: 'Recur',
      accessorKey: 'recur',
      cellFormatter: 'recurFormatter',
    },
    {
      header: 'Subscription',
      accessorKey: 'subscription',
      cellFormatter: 'subsFormatter',
    },
    {
      header: 'ThresHold',
      accessorKey: 'settings',
      cellFormatter: 'thresHoldFormatter',
    },
    {
      header: 'Role',
      accessorKey: 'user_role',
      cellFormatter: 'userRoleFormatter',
    },
    {
      header: 'User Source',
      accessorKey: 'user_source',
      cellFormatter: 'userSourceFormatter',
    },
    {
      header: 'Channel Source',
      accessorKey: 'channel_source',
      cellFormatter: 'channelSourceFormatter',
    },
    {
      header: 'Channel Source Campaign',
      accessorKey: 'channel_source',
      cellFormatter: 'channelSourceCampaignFormatter',
    },
    {
      header: 'Channel Source Query',
      accessorKey: 'channel_source',
      cellFormatter: 'channelSourceQueryFormatter',
    },
    {
      header: 'Affiliate',
      accessorKey: 'affiliate',
      cellFormatter: 'affiliateFormatter',
    },
    {
      header: 'Last Purchased On',
      accessorKey: 'tags',
      cellFormatter: 'coLastPurchasedFormatter',
    },
    {
      header: 'Total Amount Paid (in USD)',
      accessorKey: 'tags',
      cellFormatter: 'copTotalAmountPaidFormatter',
    },
    {
      header: 'Payment Count',
      accessorKey: 'tags',
      cellFormatter: 'coPaymentCountFormatter',
    },
    {
      header: 'Sign Up IP',
      accessorKey: 'ip',
      cellFormatter: 'signUpIPFormatter',
    },
    {
      header: 'Sign UP Country',
      accessorKey: 'country',
      cellFormatter: 'signUpCountryFormatter',
    },
    {
      header: 'Activated On',
      accessorKey: 'activated_on',
      cellFormatter: 'activatedOnFormatter',
    },
    {
      header: 'Created On',
      accessorKey: 'created_on',
      cellFormatter: 'createdOnFormatter',
    },
    {
      header: 'User Id',
      accessorKey: '_id',
      cellFormatter: 'userIdFormatter',
    },
  ],
  clearouts: [
    {
      header: 'Sr No.',
      accessorKey: 'sr_no',
      cellFormatter: 'srNoFormater',
    },
    {
      header: 'Name',
      accessorKey: 'name',
      cellFormatter: 'coOrgNameFormatter',
    },
    {
      header: 'Email',
      accessorKey: 'email',
      cellFormatter: 'coEmailFormatter',
    },
    {
      header: 'Credits',
      accessorKey: 'credits',
      cellFormatter: 'creditsOrgFormatter',
    },
    {
      header: 'ThresHold',
      accessorKey: 'settings',
      cellFormatter: 'thresHoldFormatter',
    },
    {
      header: 'Seats',
      accessorKey: 'used_seats_count',
      cellFormatter: 'memberSeatsFormat',
    },
    {
      header: 'Phones',
      accessorKey: 'org_tel',
      cellFormatter: 'coPhoneNumberFormatter',
    },
    {
      header: 'Website Url',
      accessorKey: 'org_website_url',
      cellFormatter: 'orgUrlFormat',
    },
    {
      header: 'Created On',
      accessorKey: 'created_on',
      cellFormatter: 'createdOnFormatter',
    },
    {
      header: 'Org ID',
      accessorKey: 'id',
      cellFormatter: 'userIdFormatter',
    },
  ],
}

export const COUPON_LIST_TABLE_COLUMNS = {
  clearout: [
    {
      header: 'Sr No.',
      accessorKey: 'sr_no',
      cellFormatter: 'srNoFormater',
    },
    {
      header: 'Coupon',
      accessorKey: 'name',
      cellFormatter: 'couponDetails',
    },
    {
      header: 'Id',
      accessorKey: 'coupon_id',
      cellFormatter: 'couponId',
    },
    {
      header: 'Redeem Count',
      accessorKey: 'any',
      cellFormatter: 'redeemCountFormat',
    },
    {
      header: 'Discount Values',
      accessorKey: 'any',
      cellFormatter: 'discountValuesFormat',
    },
    {
      header: 'Affilated To',
      accessorKey: 'affiliated_to',
      cellFormatter: 'affiliatedToFormat',
    },
    {
      header: 'Status',
      accessorKey: 'status',
      cellFormatter: 'statusFormat',
    },
    {
      header: 'Expiry(In UTC)',
      accessorKey: 'expiry',
      cellFormatter: 'formatDate',
    },
    {
      header: 'Created By',
      accessorKey: 'created',
      cellFormatter: 'createdByFormat',
    },
    {
      header: 'Applicable for',
      accessorKey: 'user_id',
      cellFormatter: 'applicableFormat',
    },


  ],
  clearoutphone: [
    {
      header: 'Sr No.',
      accessorKey: 'sr_no',
      cellFormatter: 'srNoFormater',
    },
    {
      header: 'Coupon',
      accessorKey: 'name',
      cellFormatter: 'couponDetails',
    },
    {
      header: 'Id',
      accessorKey: 'coupon_id',
      cellFormatter: 'couponId',
    },
    {
      header: 'Redeem Count',
      accessorKey: 'any',
      cellFormatter: 'redeemCountFormat',
    },
    {
      header: 'Discount Values',
      accessorKey: 'any',
      cellFormatter: 'discountValuesFormat',
    },
    {
      header: 'Affilated To',
      accessorKey: 'affiliated_to',
      cellFormatter: 'affiliatedToFormat',
    },
    {
      header: 'Status',
      accessorKey: 'status',
      cellFormatter: 'statusFormat',
    },
    {
      header: 'Expiry(In UTC)',
      accessorKey: 'expiry',
      cellFormatter: 'formatDate',
    },
    {
      header: 'Created By',
      accessorKey: 'created',
      cellFormatter: 'createdByFormat',
    },
    {
      header: 'Applicable for',
      accessorKey: 'user_id',
      cellFormatter: 'applicableFormat',
    },

  ]
}
