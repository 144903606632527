import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Modal } from 'react-bootstrap';

import * as Helper from '../../helpers/helper';
import * as Constants from '../../helpers/constants';

import ClipBoardCopy from '../widgets/clipboard_copy';
import ClearoutIndividualUserInfo from '../individual_user_info/clearout_individual_user_info';

const FileDetails = (props) => {
  const [fileDetails, setFileDetails] = useState({
    created_on: props.createdOn,
    file_type: props.fileType,
    time_taken: props.timeTaken,
    list_id: props.listId,
    mode: props.mode,
    last_verified_on: props.lastVerifiedOn,
    last_cancelled_on: props.lastCancelledOn,
    account_details: props.accountDetails,
    list_name: props.listName,
    userDetails: props.userDetails,
    verifiedOn: props.verifiedOn,
    action_inprogress: props.action_inprogress,
    progress_updated_on: props.progressUpdatedOn,
    result_expires_on: props.resultExpiresOn,
  });
  const [showModal, setShowModal]=useState(false)

  useEffect(() => {
    setFileDetails(prevDetails => ({
      ...prevDetails,
      created_on: props.createdOn,
      file_type: props.fileType,
      time_taken: props.timeTaken,
      list_id: props.listId,
      mode: props.mode,
      last_verified_on: props.lastVerifiedOn,
      last_cancelled_on: props.lastCancelledOn,
      account_details: props.accountDetails,
      list_name: props.listName,
      verifiedOn: props.verifiedOn,
      action_inprogress: props.action_inprogress,
      progress_updated_on: props.progressUpdatedOn,
      result_expires_on: props.resultExpiresOn,
    }));
  }, [props]);

  const getCreatedOnTime = (created_on) => {
    let diffInMins = moment.duration(created_on).asMinutes();
    diffInMins = Math.round(diffInMins);
    let time = diffInMins > 1 ? ' minutes ' : ' minute ';
    let readableTime = diffInMins + time + ' ';
    if (diffInMins > 1) {
      if (diffInMins > 60 && diffInMins < 1440) {
        const hours = moment.duration(created_on).hours();
        const minutes = Math.round(diffInMins - hours * 60);
        time = hours > 1 ? ' hours ' : ' hour ';
        readableTime = hours + time + minutes + ' minutes ';
      } else if (diffInMins > 1440) {
        const days = Math.floor(moment.duration(created_on).asDays());
        const hours = Math.floor((diffInMins - (days * 24 * 60)) / 60);
        const minutes = Math.round(diffInMins - (days * 24 * 60) - (hours * 60));
        time = days > 1 ? ' days ' : ' day ';
        readableTime = days + time + hours + ' hours ' + minutes + ' minutes';
      }
      return readableTime;
    } else {
      return moment.duration(created_on).humanize();
    }
  };

  const handleShowModal = () => {
    setShowModal(true)
  };

  const handleCloseModal = () => {
    setShowModal(false)
  }

  const fileImg = Helper.getFileTypeImage(fileDetails.file_type);
  const isPaid = fileDetails.userDetails && fileDetails.userDetails.tags ? Helper.getPaidTag(fileDetails.userDetails) : null;

  return (
    <div>
      <div className="d-flex align-items-center mb-2">
        <img
          className="list-uploaded-file-img"
          src={fileImg}
          alt={fileDetails.list_name}
        />
        <p
          className="file-name"
          title={fileDetails.list_name}
        >
          {Helper.truncateMiddle(fileDetails.list_name, 14)}
        </p>
        {fileDetails.result_expires_on === null || fileDetails.result_expires_on > 0 ?
          <OverlayTrigger
            trigger={['hover', 'click']}
            placement="right"
            overlay={
              <Tooltip
                id="tooltipText"
                className='position-fixed'
              >
                {'Download ' + fileDetails.list_name}
              </Tooltip>
            }
          >
            <button
              className="btn btn-link d-inline btn-input-file-download"
              style={{ padding: '0' }}
              target="blank"
              onClick={() => props.downloadInputFile(fileDetails.list_id)}
            >
              <i
                className="fa fa-download pr-1"
                style={{ paddingLeft: '5px' }}
              >
              </i>
            </button>
          </OverlayTrigger>
          
          : null}
      </div>
      {Constants.INTEGRATION_PLATFORMS.includes(fileDetails.file_type) ?
        <p className="file-uploaded-date">Account: {fileDetails.account_details.account_name} {' '} ({fileDetails.account_details.account_id})</p> : null}
      <p className="file-uploaded-date">Created : {(Helper.formatTime(new Date(), 'YYYY') === Helper.formatTime(fileDetails.created_on, 'YYYY')) ? Helper.formatTime(fileDetails.created_on, ' DD MMM hh:mm A') : Helper.formatTime(fileDetails.created_on, ' DD MMM YYYY hh:mm A')}</p>
      {fileDetails.verifiedOn ?
        <p className="file-uploaded-date">Started on : {(Helper.formatTime(new Date(), 'YYYY') === Helper.formatTime(fileDetails.verifiedOn, 'YYYY')) ? Helper.formatTime(fileDetails.verifiedOn, ' DD MMM hh:mm A') : Helper.formatTime(fileDetails.verifiedOn, ' DD MMM YYYY hh:mm A')}</p> : null}
      {fileDetails.last_verified_on ?
        <p className="file-uploaded-date">{props.listComponent === 'email-finder' ? 'Processed On :' : 'Verified On :'}{(Helper.formatTime(new Date(), 'YYYY') === Helper.formatTime(fileDetails.last_verified_on, 'YYYY')) ? Helper.formatTime(fileDetails.last_verified_on, ' DD MMM hh:mm A') : Helper.formatTime(fileDetails.last_verified_on, ' DD MMM YYYY hh:mm A')}</p> : null}
      {fileDetails.progress_updated_on ?
        <p className="file-uploaded-date">Progress Updated On : {fileDetails.progress_updated_on}</p> : null}
      {fileDetails.last_cancelled_on ?
        <p className="file-uploaded-date">Cancelled On : {Helper.formatTime(fileDetails.last_cancelled_on, ' DD MMM hh:mm A')}</p> : null}
      {fileDetails.time_taken !== null ?
        <p className="file-uploaded-date">Time Taken : {getCreatedOnTime(fileDetails.time_taken)}</p> : null}
      {fileDetails.mode !== null ?
        <p className="file-uploaded-date">Optimized For : {Constants.EMAIL_VERIFY_MODES[fileDetails.mode]}</p>
        : null}
      {props.listComponent === 'email-finder' && props.settings && props.settings.include_role_email === false
        ? <p className="file-uploaded-date">Limited To : Non-Role Based Emails</p>
        : null}
      {fileDetails.userDetails !== null ?
        <p className="file-uploaded-date">Name: {fileDetails.userDetails.name}</p> : null}
      {fileDetails.userDetails !== null ?
        <div className="d-flex justify-content-between">
          <p className="file-uploaded-date">
            Email:
            <a
              onClick={()=>handleShowModal()}
              rel='noopener noreferrer'
              className='text-decoration-none'
              style={{cursor: 'pointer'}}
            >
              {fileDetails.userDetails.email}
            </a>
          </p>
          &nbsp;&nbsp; <ClipBoardCopy
            textToCopy={fileDetails.userDetails.email}
          />
        </div> : null}
      <p className="paid-label">{isPaid ? <span className="label label-success">Paid</span> : null}</p>
      <Modal
        show={showModal}
        onHide={handleCloseModal}
        size='xl'
      >
        <Modal.Header closeButton>
          <Modal.Title className='fs-6 fw-bold'>{fileDetails.userDetails.user_id} details</Modal.Title>
        </Modal.Header>
        <Modal.Body className='modal-body-bg-color'>
          <ClearoutIndividualUserInfo
            client={props.client}
            userId={fileDetails.userDetails.user_id}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default FileDetails;
